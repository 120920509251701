import React, { memo } from "react";

const AppInsuranceIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} width="12" height="14" className="insurance-icon" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.41992 6.29337C3.41992 6.89337 3.71325 7.4667 4.30659 8.02003C4.89992 8.5667 5.39326 9.0267 5.78659 9.39337C5.85326 9.4467 5.92659 9.47337 5.99992 9.47337C6.07325 9.47337 6.14659 9.4467 6.21325 9.39337C6.60659 9.04003 7.09325 8.58003 7.68659 8.01337C8.27992 7.45337 8.57325 6.88003 8.57325 6.2867C8.57325 5.90003 8.43325 5.5667 8.15325 5.2867C7.87325 5.0067 7.53992 4.8667 7.15325 4.8667C6.91992 4.8667 6.70659 4.91337 6.49992 5.0067C6.29326 5.10003 6.12659 5.2267 5.99992 5.3867C5.87326 5.2267 5.70659 5.10003 5.49992 5.0067C5.29326 4.91337 5.07992 4.8667 4.83992 4.8667C4.45326 4.8667 4.11992 5.0067 3.83992 5.2867C3.55992 5.5667 3.41992 5.90003 3.41992 6.2867L3.41992 6.29337Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.00431 0.49317C6.00517 0.493172 6.00592 0.493173 6.00657 0.493173C6.05628 0.493173 6.1055 0.503023 6.12134 0.506192C6.13367 0.508659 6.14508 0.511184 6.15278 0.51296L6.16244 0.51524L6.16556 0.516L6.16665 0.516269L6.16734 0.51644C6.17954 0.51949 6.19163 0.523246 6.20341 0.527654L10.6567 2.19432C10.6616 2.19614 10.6664 2.19808 10.6712 2.20013C10.7759 2.24497 10.8672 2.31984 10.9306 2.41494C10.9926 2.50794 11.0266 2.61195 11.0266 2.73317L11.0266 6.27317C11.0266 7.91926 10.5669 9.42518 9.6415 10.775C8.73065 12.1036 7.56686 13.0056 6.16513 13.4699C6.11936 13.4934 6.06765 13.5065 6.01324 13.5065L5.9999 13.5065C5.94571 13.5065 5.89418 13.4935 5.84855 13.4702C4.43968 13.0058 3.28228 12.1033 2.37164 10.775C1.44626 9.42518 0.986572 7.91926 0.986572 6.27317L0.986572 2.73317C0.986572 2.62349 1.01787 2.51197 1.08256 2.41494C1.13654 2.33397 1.22041 2.24532 1.3562 2.1944L5.79082 0.534732C5.84807 0.507691 5.89973 0.500111 5.92513 0.497123C5.95957 0.493071 5.99214 0.493143 6.00431 0.49317ZM6.0063 12.82C7.23515 12.4007 8.26695 11.601 9.09164 10.398C9.9396 9.16116 10.3599 7.78709 10.3599 6.27317L10.3599 2.79505L6.00657 1.16581L1.65324 2.79505L1.65324 6.27317C1.65324 7.78709 2.07355 9.16116 2.9215 10.398C3.74658 11.6015 4.77226 12.4008 6.0063 12.82Z"
				fill={color}
			/>
		</svg>
	);
};

export default memo(AppInsuranceIcon);
