import React, { useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT, formatDateTime } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import getOccupationListing from "services/get-occupation-listing";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppSelectStatus from "components/app-select-status";
import AppCalendarInput from "components/app-calendar-input";

const PageUser = (props) => {
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const updateAccessible = restrictedActions(ROLES.USER, ROLES.UPDATE_USER);
	const initialValues = useMemo(() => ({ status: undefined, dateOfBirth: "", name: "", mobile: "", email: "", occupation: "", referralCode: "", lastModifiedByName: "", createdDate: new Date() }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			occupation: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			name: yup.string().required(ERRORS.REQUIRED),
			dateOfBirth: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isStatusDeleted = useMemo(() => formik.values.status === -1, [formik.values.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => isStatusDeleted || !updateAccessible || formik.isSubmitting, [isStatusDeleted, updateAccessible, formik.isSubmitting]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				id,
				fullName: values.name,
				dateOfBirth: values.dateOfBirth,
				occupation: values.occupation,
				status: values.status,
			};

			await api.post.customer.update(payload);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: `${values.name} has been updated successfull` }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.customer.customer(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					status: response.status,
					dateOfBirth: response.dateOfBirth || "",
					name: response.fullName || "",
					mobile: response.mobileNoPrefix + response.mobileNo || "",
					email: response.email,
					occupation: response.occupation,
					referralCode: response.referralCode,
					lastModifiedDate: response.lastModifiedDate,
					lastModifiedByName: response.lastModifiedByName,
				});
			}
		};

		if (isEdit) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CUSTOMER.CUSTOMER);
		};
	}, [cancelRequest]);

	return (
		<div className="page-customer">
			<div className="customer">
				<h1 className="customer__name">{formik.values.name}</h1>
				<p className="customer__date">
					Last updated by: {formik.values.lastModifiedByName}, {formatDateTime(new Date(formik.values.lastModifiedDate))}
				</p>

				<form className="customer__form" onSubmit={formik.handleSubmit}>
					<div className="customer__container">
						<div className="customer__box">
							<div className="customer__wrapper">
								<div className="customer__box-body">
									{!isStatusDeleted && <AppSelectStatus value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} disabled={isDisabled} onChange={formik.setFieldValue} />}

									{/* prettier-ignore */}
									<AppInput type="text" name="referralCode" label="Referral Code" placeholder="Referral Code" value={formik.values.referralCode} disabled />

									{/* prettier-ignore */}
									<AppInput required type="text" name="name" label="Name as per NRIC/Passport" placeholder="Name as per NRIC/Passport" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppCalendarInput required name="dateOfBirth" label="Date of Birth" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.dateOfBirth} error={formik.errors.dateOfBirth} touched={formik.touched.dateOfBirth} disabled={isDisabled} onChange={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="mobile" label="Mobile No." placeholder="Mobile No." value={formik.values.mobile} disabled />

									{/* prettier-ignore */}
									<AppInput type="text" name="email" label="Personal Email Address" placeholder="Email Address" value={formik.values.email} disabled />

									{/* prettier-ignore */}
									<AppSelectInput required type="text" name="occupation" label="Occupation" placeholder="Please Select" loadOptions={getOccupationListing} value={formik.values.occupation} error={formik.errors.occupation} touched={formik.touched.occupation} disabled={isDisabled} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="customer__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={isDisabled} label="Update" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageUser;
