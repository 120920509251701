import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, Fragment } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const TAB = {
	APPLICATANT_INFORMATION: "APPLICATANT_INFORMATION",
	VEHICLES_DETAILS: "VEHICLES_DETAILS",
	SUMMARY: "SUMMARY",
};

const TABS = [
	{ label: "Applicant Information", value: TAB.APPLICATANT_INFORMATION },
	{ label: "Vehicle Details", value: TAB.VEHICLES_DETAILS },
	{ label: "Summary", value: TAB.SUMMARY },
];

export const AppEnquiryMotorModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TAB.APPLICATANT_INFORMATION);

	const downloadEnquiryClassName = useMemo(() => classNames({ "enquiry-motor-modal__download-button": true, "enquiry-motor-modal__download-button--disabled": !data?.summary?.policyDocument }), [data]);

	const productInfo = useMemo(() => {
		const motorApplicantInfo = data?.motorApplicantInfo;

		const info = [
			{ label: "Name", value: motorApplicantInfo?.name || "-" },
			{ label: "Identification Type", value: motorApplicantInfo?.identificationType || "-" },
			{ label: "NRIC No. / Passport", value: motorApplicantInfo?.nric || motorApplicantInfo?.passport || "-" },
			{ label: "Date of Birth", value: motorApplicantInfo?.dateOfBirth ? formatDatePattern(motorApplicantInfo?.dateOfBirth) : "-" },
			{ label: "Email Address", value: motorApplicantInfo?.email || "-" },
			{ label: "Mobile No.", value: motorApplicantInfo?.mobileNumber || "-" },
			{ label: "Gender", value: motorApplicantInfo?.gender || "-" },
			{ label: "Marital Status", value: motorApplicantInfo?.maritalStatus || "-" },
			{ label: "Correspondence Address (Line 1)", value: motorApplicantInfo?.address.addressLine1 || "-" },
			{ label: "Correspondence Address (Line 2)", value: motorApplicantInfo?.address.addressLine2 || "-" },
			{ label: "State", value: motorApplicantInfo?.address.state || "-" },
			{ label: "City", value: motorApplicantInfo?.address.city || "-" },
			{ label: "Postcode", value: motorApplicantInfo?.address.postcode || "-" },
		];
		return info;
	}, [data]);

	const vehicleInfo = useMemo(() => {
		const vehicleDetails = data?.vehicleDetails;

		const info = [
			{ label: "Registration No.", value: vehicleDetails?.vehRegNo || "-" },
			{ label: "Model", value: vehicleDetails?.model || "-" },
			{ label: "Make Year", value: vehicleDetails?.makeYear || "-" },
			{ label: "Engine Displacement (CC)", value: vehicleDetails?.capacity || "-" },
			{ label: "Chasis No.", value: vehicleDetails?.chassisNo || "-" },
			{ label: "Engine No.", value: vehicleDetails?.engineNo || "-" },
			{ label: "Seat", value: vehicleDetails?.seat || "-" },
			{ label: "Transmission", value: vehicleDetails?.transmissionType || "-" },
			{ label: "Build Type", value: vehicleDetails?.buildType || "-" },
			{ label: "ISM Market Value", value: vehicleDetails?.ismMarketValue ? `RM ${formatCurrency(vehicleDetails?.ismMarketValue)}` : "-" },
		];

		return info;
	}, [data]);

	const summaryInfo = useMemo(() => {
		const summary = data?.summary;

		const info = [
			{ label: "Insurer", value: summary?.insurerName || "-" },
			{ label: "Coverage Type", value: summary?.coverageType || "-" },
		];

		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		console.log(id + " is the id")
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setTab(TAB.APPLICATANT_INFORMATION);
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.motor.enquiry.motor(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-enquiry-motor-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="enquiry-motor-modal" aria-describedby="enquiry-motor-profile-modal">
			<div className="enquiry-motor-modal">
				<div className="enquiry-motor-modal__main">
					<button className="enquiry-motor-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="enquiry-motor-modal__header">
						<div className="enquiry-motor-modal__profile">
							<p className="enquiry-motor-modal__title">Enquiry Details</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="enquiry-motor-modal__text">Enquiry ID</p>
							<p className="enquiry-motor-modal__title">{data.enquiryId}</p>
						</div>
						<div className="info__box">
							<p className="enquiry-motor-modal__text">Status</p>
							<span className="enquiry-motor-modal__title">
								<AppStatus status={data.status || "-"} />
							</span>
						</div>
					</div>

					<AppScrollableTabs tabs={TABS} onChange={setTab} />

					{TAB.APPLICATANT_INFORMATION === tab && (
						<div className="enquiry-motor-modal__body">
							<div className="enquiry-motor-modal__container">
								<div className="enquiry-motor-modal__wrapper">
									{productInfo.map((o, i) => {
										return (
											<div className="enquiry-motor-modal__info" key={i}>
												<p className="enquiry-motor-modal__label">{o.label}</p>
												<p className="enquiry-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}

					{TAB.VEHICLES_DETAILS === tab && (
						<div className="enquiry-motor-modal__body">
							<div className="enquiry-motor-modal__container">
								<div className="enquiry-motor-modal__wrapper">
									{vehicleInfo.map((o, i) => {
										return (
											<div className="enquiry-motor-modal__info" key={i}>
												<p className="enquiry-motor-modal__label">{o.label}</p>
												<p className="enquiry-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}

					{TAB.SUMMARY === tab && (
						<div className="enquiry-motor-modal__body">
							<div className="enquiry-motor-modal__container">
								<div className="enquiry-motor-modal__wrapper">
									{summaryInfo.map((o, i) => {
										return (
											<div className="enquiry-motor-modal__info" key={i}>
												<p className="enquiry-motor-modal__label">{o.label}</p>
												<p className="enquiry-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>

								<div className="enquiry-motor-modal__plan-coverage">
									{Boolean(data.summary?.selectedExtraBenefit?.length) && (
										<Fragment>
											<p className="enquiry-motor-modal__title">Plan Coverage</p>

											<div className="box">
												<div className="box__header">
													<p className="box__headline">Additional Benefits</p>
												</div>

												{data.summary.selectedExtraBenefit.map((o, i) => {
													return (
														<div className="box__container" key={i}>
															<div className="box__list">
																<p className="box__label">{o.benefitDescription}</p>
																<p className="box__text">RM {formatCurrency(o.benefitPremium)}</p>
															</div>
														</div>
													);
												})}
											</div>
										</Fragment>
									)}

									{data.summary && (
										<div className="box">
											<div className="box__header">
												<p className="box__headline">Basic Premium</p>
											</div>

											<div className="box__container">
												<div className="box__list">
													<p className="box__label">Premium Amount</p>
													<p className="box__text">RM {formatCurrency(data.summary.tariffPremium)}</p>
												</div>
												<div className="box__list">
													<p className="box__label">No Claim Discount (NCD) {parseInt(data.summary.ncd)}%</p>
													<p className="box__text">- RM {formatCurrency(data.summary.ncdAmount)}</p>
												</div>
												<div className="box__list">
													<p className="box__label">Premium Net of NCB</p>
													<p className="box__text">RM {formatCurrency(data.summary.tariffPremium - data.summary.ncdAmount)}</p>
												</div>
											</div>
										</div>
									)}

									<div className="enquiry-motor-modal__container">
										<p className="enquiry-motor-modal__title">Payment Details</p>

										<div className="enquiry-motor-modal__row">
											<p className="enquiry-motor-modal__label">Gross Premium</p>
											<p className="enquiry-motor-modal__total">RM {formatCurrency(data.summary?.grossPremium)}</p>
										</div>

										<div className="enquiry-motor-modal__row">
											<p className="enquiry-motor-modal__label">{`SST (8%)`}</p>
											<p className="enquiry-motor-modal__total">RM {formatCurrency(data.summary?.sst)}</p>
										</div>

										<div className="enquiry-motor-modal__row">
											<p className="enquiry-motor-modal__label">Stamp Duty</p>
											<p className="enquiry-motor-modal__total">RM {formatCurrency(data.summary?.stampDuty)}</p>
										</div>

										<div className="enquiry-motor-modal__row">
											<p className="enquiry-motor-modal__label enquiry-motor-modal__label--bold">Total payable:</p>
											<p className="enquiry-motor-modal__total enquiry-motor-modal__total--bold">RM {formatCurrency(data?.summary?.grossPremium + data?.summary?.sst + data?.summary?.stampDuty || 0)}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="enquiry-motor-modal__footer">
								<a className={downloadEnquiryClassName} href={data.summary?.policyDocument} target="_blank" rel="noreferrer">
									Download Policy <UploadIcon />
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEnquiryMotorModal));

AppEnquiryMotorModal.propTypes = {
	ref: PropTypes.object,
};
