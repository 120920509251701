import React, { useRef, useEffect, useMemo, useCallback, useState, Fragment } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { AppTableCell, AppTableEditIcon, indexing } from "components/app-table";
import AppExportReportModal, { TYPES } from "components/pages/insurance-management/app-export-report-modal";
import exportIcon from "assets/images/export-icon.svg";
import previewIcon from "assets/images/preview-icon.svg";
const TAB = "TRAVEL";

const AppApplicationTravelTable = (props) => {
	const tableRef = useRef();
	const searchInputRef = useRef();
	const productModalRef = useRef();
	const enquiryMotorModalRef = useRef();
	const exportReportModalRef = useRef();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const propTab = useMemo(() => props.tab, [props.tab]);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		identificationNumber: searchParams.get("identificationNumber") || "",
		tab: propTab,
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const initialValues = useMemo(() => ({ identificationNumber: "", }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.identificationNumber && !paramsRef.current.enquiryNo, [data.status]);
	const isSearchState = useCallback(() => {
		const { page, sort, tab, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { identificationNumber: params.identificationNumber, };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;
		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };
			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;
			response = await api.get.travel.order.list(sanitizeObject(payload));			
		} catch (error) {
			serveRequestErrors(error);
		}
		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				items: response.data.data,
				total: response.data.totalPages,
				totalElements: response.data.totalElements,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		//enquiryMotorModalRef.current.onHandleShow(id);
		navigate(pathnames.insuranceManagement.ordersTravel + id);
	}, []);

	const onHandleExportReport = useCallback(() => {
		exportReportModalRef.current.onHandleShow();
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };
		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const CreatedCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.createdDate))} />;
	}, []);

	const LastModifiedDate = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.lastModifiedDate))} />;
	}, []);
	const PaymentDate = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.paymentDate))} />;
	}, []);
	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(row.original.id)} />; 
	}, [onHandleEdit]);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Vendor Payment Id",
			accessor: "vendorPaymentId",
			disableSortBy: true,
		},
		{
			Header: "Provider Name",
			accessor: "providerTypeName",
			disableSortBy: true,
		},
		{
			Header: "Payment Type",
			accessor: "paymentType",
			disableSortBy: false,
		},
		{
			Header: "Amount Cents",
			accessor: "amountCents",
			disableSortBy: true,
		},
		{
			Header: "Payment Date",
			accessor: "paymentDate",
			disableSortBy: true,
			Cell: PaymentDate,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, CreatedCell, StatusCell, LastModifiedDate]);

	useEffect(() => {
		onHandleGetList();
	}, [propTab, onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	return (
		<Fragment>
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="orders">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonLabel="Export" buttonIcon={exportIcon} onButtonClick={onHandleExportReport}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="identificationNumber" label="NRIC No. / Passport" placeholder="Enter NRIC No. / Passport" value={formik.values.identificationNumber} onChange={formik.handleChange} />
								
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="orders__results">
							<p className="orders__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort}/>
				</div>
			)}
			
			<AppExportReportModal ref={exportReportModalRef} type={TYPES.MOTOR_ENQUIRY} />

		</Fragment>
	);
};

export default AppApplicationTravelTable;
