import React, { useState, useCallback, memo, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import AppChevronIcon from "components/icons/app-chevron-icon";

const AppScrollableTabs = (props) => {
	const [tab, setTab] = useState(props.tabs?.[0]?.value || "");
	const firstItemActive = useMemo(() => props.tabs?.[0]?.value === tab, [tab, props.tabs]);
	const lastItemActive = useMemo(() => props.tabs?.[props.tabs?.length - 1]?.value === tab, [tab, props.tabs]);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"app-scrollable-tabs__item": true,
			"app-scrollable-tabs__item--active": tab === currentTab,
		});
	}, [tab]);

	//prettier-ignore
	const onHandleTabChange = useCallback((event) => {
        const item = event.currentTarget;

        const list = document.querySelector(".app-scrollable-tabs__list");

        const items = document.querySelectorAll(".app-scrollable-tabs__item");

        const attributeTab = item.getAttribute("data-tab");

        let stopCount = false;

        let totalScrollLeft = 0;

        items.forEach((o) => {
            const dataSet = o.dataset.tab;

            if (dataSet === attributeTab) stopCount = true;

            if (stopCount) return;

            totalScrollLeft += o.scrollWidth;
        });

        list.scrollLeft = totalScrollLeft;

        setTab(attributeTab);

        if (props.onChange) props.onChange(attributeTab);
    }, [props]);

	//prettier-ignore
	const onHandleButtonScroll = useCallback((event) => {
        const item = event.currentTarget;

		const attributePosition = item.getAttribute("data-position");

        const list = document.querySelector(".app-scrollable-tabs__list");

        const items = document.querySelectorAll(".app-scrollable-tabs__item");

        let totalScrollLeft = 0;

       
        items.forEach((o, i) => {
            const dataSet = o.dataset.tab;

            totalScrollLeft += o.scrollWidth;

            if (dataSet === tab) {
                let index = 0;

                if(attributePosition === "left") index = i - 1 < 0 ? 0 : i - 1;
                

                if(attributePosition === "right") index = i + 1 > items.length ? items.length : i + 1;

                if(items[index]) {
                    const nextTab = items[index].dataset.tab;

                    setTab(nextTab);

                    if(props.onChange) props.onChange(nextTab);


                    if(attributePosition === "left") list.scrollLeft = items[index].scrollLeft;

                    if(attributePosition === "right") list.scrollLeft = totalScrollLeft;
                }
            }
        });

	}, [props, tab]);

	useEffect(() => {
		const scrollableTabs = document.querySelector(".app-scrollable-tabs");
		const leftScrollButton = document.querySelector(".app-scrollable-tabs__scroll-left");
		const rightScrollButton = document.querySelector(".app-scrollable-tabs__scroll-right");

		const onMouseOver = () => {
			if (firstItemActive) {
				rightScrollButton.classList.add("app-scrollable-tabs__scroll-right--active");
			} else {
				leftScrollButton.classList.add("app-scrollable-tabs__scroll-left--active");
			}
		};

		const onMouseOut = () => {
			rightScrollButton.classList.remove("app-scrollable-tabs__scroll-right--active");

			leftScrollButton.classList.remove("app-scrollable-tabs__scroll-left--active");
		};

		scrollableTabs.addEventListener("mouseover", onMouseOver);

		scrollableTabs.addEventListener("mouseout", onMouseOut);
	}, [firstItemActive]);

	return (
		<div className="app-scrollable-tabs">
			<button className="app-scrollable-tabs__scroll-left" data-position="left" disabled={firstItemActive} onClick={onHandleButtonScroll}>
				<AppChevronIcon />
			</button>

			<ul className="app-scrollable-tabs__list">
				{props.tabs?.map((o) => {
					return (
						<li className={tabClassName(o.value)} key={o.value} data-tab={o.value} onClick={onHandleTabChange} onKeyDown={() => {}}>
							<p className="app-scrollable-tabs__label">{o.label}</p>
						</li>
					);
				})}
			</ul>

			<button className="app-scrollable-tabs__scroll-right" data-position="right" disabled={lastItemActive} onClick={onHandleButtonScroll}>
				<AppChevronIcon />
			</button>
		</div>
	);
};

export default memo(AppScrollableTabs);

AppScrollableTabs.propTypes = {
	onChange: PropTypes.func,
};
