import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";

import classNames from "common/class-names";

//prettier-ignore
const AppTabs = (props) => {
    const tabClassName = useCallback((value) => classNames({
        "tabs__tab": true,
        "tabs__tab--active": props.value === value || !("value" in props)
    }), [props]);

    const onHandleChange = useCallback((value) => {
        props.onChange(value);
    }, [props]);

	return (
		<div className="app-tabs">
			<ul className="tabs">
				{props.tabs.map((o, i) => <li className={tabClassName(o.value)} key={i} onClick={() => onHandleChange(o.value)}>{o.label}</li>)}
			</ul>
		</div>
	);
};

export default memo(AppTabs);

AppTabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}).isRequired
	),
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
