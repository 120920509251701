import store from "store/store";
import { promptVersionAlertMessage } from "store/slices/version-alert";

const autoUpdateWindow = async () => {
	let oldScript = [];
	let newScript = [];

	const getHTML = async () => {
		const html = await fetch("/").then((res) => res.text()); //读取index html
		return html;
	};

	const init = async () => {
		const html = await getHTML();
		oldScript = parserScript(html);
	};

	const parserScript = (html) => {
		const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/gi); //script正则
		return html.match(reg); //匹配script标签
	};

	const compare = (oldArr, newArr) => {
		const base = oldArr.length;
		const arr = Array.from(new Set(oldArr.concat(newArr)));

		//如果新旧length 一样无更新
		if (arr.length === base) {
			// no update
		} else {
			store.dispatch(promptVersionAlertMessage({ message: "There's a new version available,\nplease update for a better user experience." }));
		}
	};

	setInterval(async () => {
		const newHTML = await getHTML();
		newScript = parserScript(newHTML);
		compare(oldScript, newScript);
	}, 1800000);

	init();
};

export default autoUpdateWindow;
