import React, { memo } from "react";

const AppChevronIcon = ({ color = "#666666", ...props }) => {
	return (
		<svg {...props} width="100%" height="100%" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.55127 0.713867L4.00002 3.28637L6.44877 0.713867" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppChevronIcon);
