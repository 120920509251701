import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, Fragment } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";

const TAB = {
	TRIP_DETAILS: "TRIP_DETAILS",
};

const TABS = [
	{ label: "Applicant Information", value: TAB.TRIP_DETAILS },
];

export const AppEnquiryTravelModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TAB.TRIP_DETAILS);

	const downloadEnquiryClassName = useMemo(() => classNames({ "enquiry-motor-modal__download-button": true, "enquiry-motor-modal__download-button--disabled": !data?.summary?.policyDocument }), [data]);

	const productInfo = useMemo(() => {
		const travelEnquiryInfo = data;

		const info = [
			{ label: "Owner Id", value: travelEnquiryInfo?.ownerId || "-" },
			{ label: "Trip Type", value: travelEnquiryInfo?.tripType || "-" },
			{ label: "Coverage Type", value: travelEnquiryInfo?.coverageType || "-" },
			{ label: "Number of Seniors", value: travelEnquiryInfo?.numberOfSenior || "-" },
			{ label: "Number of Adults", value: travelEnquiryInfo?.numberOfAdults || "-" },
			{ label: "Number of Children", value: travelEnquiryInfo?.numberOfChildren || "-" },
			{ label: "Start Date", value: travelEnquiryInfo?.travelStartDate ? formatDatePattern(travelEnquiryInfo?.travelStartDate) : "-" },
			{ label: "End Date", value: travelEnquiryInfo?.travelEndDate ? formatDatePattern(travelEnquiryInfo?.travelEndDate) : "-" },
			{ label: "Provider Area Id", value: travelEnquiryInfo?.providerAreaId || "-" },
			{ label: "Destination", value: travelEnquiryInfo?.destinationCountry || "-" },
			{ label: "Referral Code", value: travelEnquiryInfo?.referralCode || "-" },
		];
		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setTab(TAB.TRIP_DETAILS);
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				const payload = { id: data.id };
				response = await api.get.travel.enquiry.list(payload);		
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response.data.data[0] }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-enquiry-motor-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="enquiry-motor-modal" aria-describedby="enquiry-motor-profile-modal">
			<div className="enquiry-motor-modal">
				<div className="enquiry-motor-modal__main">
					<button className="enquiry-motor-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="enquiry-motor-modal__header">
						<div className="enquiry-motor-modal__profile">
							<p className="enquiry-motor-modal__title">Enquiry Details</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="enquiry-motor-modal__text">Enquiry ID</p>
							<p className="enquiry-motor-modal__title">{data.enquiryId}</p>
						</div>
						{/* <div className="info__box">
							<p className="enquiry-motor-modal__text">Status</p>
							<span className="enquiry-motor-modal__title">
								<AppStatus status={data.status || "-"} />
							</span>
						</div> */}
					</div>

					<AppScrollableTabs tabs={TABS} onChange={setTab} />

					{TAB.TRIP_DETAILS === tab && (
						<div className="enquiry-motor-modal__body">
							<div className="enquiry-motor-modal__container">
								<div className="enquiry-motor-modal__wrapper">
									{productInfo.map((o, i) => {
										return (
											<div className="enquiry-motor-modal__info" key={i}>
												<p className="enquiry-motor-modal__label">{o.label}</p>
												<p className="enquiry-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>
					
							<div className="enquiry-motor-modal__footer">
								<a className={downloadEnquiryClassName} href={data.summary?.policyDocument} target="_blank" rel="noreferrer">
									Download Policy <UploadIcon />
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEnquiryTravelModal));

AppEnquiryTravelModal.propTypes = {
	ref: PropTypes.object,
};
