import React, { memo, useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";

import AppChevronIcon from "components/icons/app-chevron-icon";

const configs = {
	yFormat: " >-.2f",
	curve: "natural",
	enableSlices: "x",
	crosshairType: "x",
	xScale: { type: "point" },
	yScale: { type: "linear", min: 0, max: "auto", stacked: false, reverse: false },
	margin: { top: 15, right: 20, bottom: 50, left: 60 },
	axisLeft: { tickSize: 0, tickPadding: 20 },
	axisBottom: { tickSize: 0, tickPadding: 20 },
	axisTop: null,
	useMesh: true,
	axisRight: null,
	enableArea: true,
	enableGridX: false,
	animate: true,
	gridYValues: 6,
	areaOpacity: 0.1,
	areaBaselineValue: 0,
	enablePoints: true,
	pointSize: 7,
	pointColor: "#ffffff",
	pointBorderWidth: 2,
	pointLabelYOffset: -12,
	pointBorderColor: { from: "serieColor" },
	defs: [
		linearGradientDef("A", [
			{ offset: 0, color: "hsla(184, 71%, 57%, 1)" },
			{ offset: 85, color: "hsla(184, 71%, 57%, 0.5)" },
			{ offset: 100, color: "rgba(0, 0, 0, 0.1)" },
		]),
		linearGradientDef("B", [
			{ offset: 0, color: "hsla(236, 82%, 65%, 1)" },
			{ offset: 85, color: "hsla(236, 82%, 65%, 0.5)" },
			{ offset: 100, color: "rgba(0, 0, 0, 0.1)" },
		]),
	],
	fill: [
		{ match: { color: "hsla(184, 71%, 57%, 1)" }, id: "A" },
		{ match: { color: "hsla(236, 82%, 65%, 1)" }, id: "B" },
	],
	theme: {
		fontFamily: "Poppins",
		fontSize: 14,
		textColor: "rgba(102, 102, 102, 1)",
		grid: {
			line: {
				stroke: "rgba(224, 224, 224, 0.7)",
				strokeDasharray: "4 4",
				strokeWidth: 1,
			},
		},
		crosshair: {
			line: {
				stroke: "rgba(192, 192, 192, 1)",
				strokeDasharray: "4 4",
				strokeWidth: 1.5,
			},
		},
	},
};

const AppLineChart = ({ data }) => {
	const [onLoaded, setOnLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setOnLoaded(true);
		}, 1000);
	}, []);

	return (
		<div className="app-line-chart">
			<div className="line-chart">
				<ResponsiveLine
					{...configs}
					axisLeft={onLoaded ? configs.axisLeft : null}
					data={data}
					colors={(d) => d.color}
					onMouseEnter={(_datum, event) => {
						event.currentTarget.style.cursor = "pointer";
					}}
					sliceTooltip={({ slice: { points } }) => {
						return points.map((o, i) => {
							return (
								<div key={i} className="line-chart__tooltips">
									<div className="line-chart__container">
										<div className="line-chart__header">
											<p className="line-chart__date">{o.data.x} 2023</p>
										</div>

										<ul className="line-chart__list">
											<li className="line-chart__item">
												<p className="line-chart__title">{o.serieId}</p>
												<p className="line-chart__total">
													<span>Total Revenue</span>RM 580,000.00
												</p>
											</li>
											<li className="line-chart__item">
												<p className="line-chart__label">• Employee Benefits</p>
												<p className="line-chart__value">RM 300,000.00</p>
											</li>
											<li className="line-chart__item">
												<p className="line-chart__label">• Assets Coverage</p>
												<p className="line-chart__value">RM 100,000.00</p>
											</li>
											<li className="line-chart__item">
												<p className="line-chart__label">• Legal Protection</p>
												<p className="line-chart__value">RM 100,000.00</p>
											</li>
										</ul>
									</div>
								</div>
							);
						});
					}}
				/>
			</div>

			<ul className="line-chart__legends">
				<button className="line-chart__summary">
					View Revenue Summary
					<div className="line-chart__icon">
						<AppChevronIcon color="#018897" />
					</div>
				</button>

				{data.map((o, i) => {
					return (
						<li key={i} className="line-chart__legend">
							<span className="line-chart__legend-indicator" style={{ backgroundColor: o.color }} />
							{o.label}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(AppLineChart);
