import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";

import COMMON from "common";
import classNames from "common/class-names";

//prettier-ignore
const AppSelectStatus = (props) => {
	const className = useCallback((type) => {
		return classNames({
			"app-select-status__button": true,
			"app-select-status__button--active": type === props.value,
		});
	}, [props.value]);

	return (
        <div className="app-select-status">
            <p className="app-select-status__label">Status<span className="app-select-status__required">*</span></p>

            <div className="app-select-status__container">
                <button type="button" className={className(COMMON.STATUS_ID.ACTIVE)} disabled={props.disabled} onClick={() => props.onChange("status", COMMON.STATUS_ID.ACTIVE)}>Active</button>
                <button type="button" className={className(COMMON.STATUS_ID.INACTIVE)} disabled={props.disabled} onClick={() => props.onChange("status", COMMON.STATUS_ID.INACTIVE)}>Inactive</button>
            </div>

            <p className="app-select-status__error">{props.touched && props.error ? props.error : ""}</p>
        </div>
    );
};

export default memo(AppSelectStatus);

AppSelectStatus.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	touched: PropTypes.bool,
	error: PropTypes.string,
	value: PropTypes.string,
};
