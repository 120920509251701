import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import logo from "assets/images/logo.webp";
import { CALENDAR_FORMAT } from "common/calendar";

const PageForgotPasswordSuccess = () => {
	const navigate = useNavigate();
	//prettier-ignore
	const expiredDate = useMemo(() => moment().add(2, "hours").format(`${CALENDAR_FORMAT.DATE_FORMAT}/${CALENDAR_FORMAT.MONTH_NUMBER_FORMAT}/${CALENDAR_FORMAT.YEAR_FORMAT} HH:mmA`), []);

	const onHandleNavigate = useCallback(() => {
		navigate(pathnames.login);
	}, [navigate]);

	return (
		<div className="page-forgot-password-success">
			<div className="forgot-password-success">
				<main className="main">
					<div className="main__content main__content--background">
						<Link to={pathnames.login} className="main__logo">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div className="main__content main__content--content">
						<div className="main__wrapper">
							<h1 className="main__title">Success!</h1>
							<p className="main__description">
								We've sent a temporary link to your email address. Please check your inbox, and note that the link will expire in <span className="main__times">{expiredDate}</span>, so be sure to use it soon.
							</p>

							<div className="main__button-container">
								<AppButton type="button" label="Done" onClick={onHandleNavigate} />
							</div>
						</div>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageForgotPasswordSuccess;
