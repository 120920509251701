import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, Fragment, useMemo, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import AppCheckbox from "components/app-checkbox";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";
import COMMON from "common";

export const AppDownloadPaymentReceiptModal = (props, ref) => {
	const paymentHistoryType = useRef();
	const [paymentRef, setPaymentRef] = useState({});
	const [visible, setVisible] = useState(false);
	const [documents, setDocuments] = useState();
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [selectedReceipt, setSelectedReceipt] = useState([]);
	const selectedAll = useMemo(() => documents?.length === selectedReceipt?.length, [selectedReceipt, documents]);

	const onHandleGetDocuments = useCallback(async (obj) => {
		setPaymentRef(obj);

		let response = null;

		try {
			if (paymentHistoryType.current === COMMON.POLICY.PAYMENT_TYPE.CREDIT_NOTE) {
				response = await api.get.policies.creditPaymentReceipt(obj.id);
			} else {
				response = await api.get.policies.debitPaymentReceipt(obj.id);
			}
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setDocuments(response);
		}
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj, type) => {
		setVisible(true);
		paymentHistoryType.current = type;
		onHandleGetDocuments(obj);
	}, [onHandleGetDocuments]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setSelectedReceipt([]);
		setDownloadProgress(0);
		setDocuments();
	}, []);

	//prettier-ignore
	const onHandleSelectInvoice = useCallback((event) => {
        const name = event.target.name;
        const isSelectedAllField = name === "all";

        if (isSelectedAllField) {
            let next = [];

            if(!selectedAll) next = documents.map((a) => a.id.toString());
            
            setSelectedReceipt(next);
        } else {
            setSelectedReceipt((prev) => {
                const current = [...prev];
                const found = current.findIndex((o) => o === name) > -1;
                const next = current.filter((o) => o !== name);

                if (!found) next.push(name);

                return next;
            });
        }
    }, [documents, selectedAll]);

	const onHandleDownloadDocuments = useCallback(async () => {
		let response = null;
		let fileName = "";
		let payload = null;

		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];
	
				if (fileName) return data;
	
				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			if (paymentHistoryType.current === COMMON.POLICY.PAYMENT_TYPE.CREDIT_NOTE) {
				payload = { refNo: paymentRef.refNo, refundFileIds: selectedReceipt };
				response = await api.post.policies.downloadCreditReceipt(payload, { onDownloadProgress, transformResponse });
			} else {
				payload = { trxNo: paymentRef.trxNo, receiptFileIds: selectedReceipt };
				response = await api.post.policies.downloadDebitReceipt(payload, { onDownloadProgress, transformResponse });
			}
		} catch (error) {
			setDownloadProgress(0);
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
			}, 1000);
		}
	}, [paymentRef, selectedReceipt]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".download-payment-receipt__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (documents?.files) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [documents]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-download-payment-receipt-modal" }} open={visible} aria-labelledby="download-payment-receipt" aria-describedby="download-payment-receipt-modal">
			<div className="download-payment-receipt">
				<div className="download-payment-receipt__header">
					<p className="download-payment-receipt__title">Download Receipt</p>
					<p className="download-payment-receipt__description">Please select receipt to download</p>
				</div>

				{!documents && (
					<div className="download-payment-receipt__loading">
						<LoadingLogo />
					</div>
				)}

				{documents && (
					<Fragment>
						<ul className="download-payment-receipt__list">
							<div className="download-payment-receipt__list-header">
								All
								<AppCheckbox label="" onClick={onHandleSelectInvoice} name="all" value={selectedAll} />
							</div>

							{documents.map((a) => {
								const found = selectedReceipt?.findIndex((c) => c === a.id) > -1;

								return (
									<Fragment key={a.filePath}>
										<li className="download-payment-receipt__item">
											{a.fileName}
											<AppCheckbox label="" onClick={onHandleSelectInvoice} name={a.id} value={found} />
										</li>
									</Fragment>
								);
							})}
						</ul>
					</Fragment>
				)}

				<div className="download-payment-receipt__progress-bar" style={{ width: `${downloadProgress}%` }} />

				<div className="download-payment-receipt__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Download" onClick={onHandleDownloadDocuments} disabled={!!downloadProgress || !selectedReceipt.length} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDownloadPaymentReceiptModal));

AppDownloadPaymentReceiptModal.propTypes = {
	ref: PropTypes.object,
};
