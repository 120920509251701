import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, Fragment } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import converReadableFileSize from "common/convert-readable-file-size";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";

const TABS = {
	SUMMARY: "SUMMARY",
	DOCUMENTS: "DOCUMENTS",
};

export const AppProductModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS.SUMMARY);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"product-modal__tab": true,
			"product-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const companyAddress = useCallback((company) => {
		if (!company) return "-";

		let address = [];

		address.push(company.address1);

		if (company.address2) address.push(company.address2);

		address.push(company.postcode);

		address.push(company.state);

		address.push(company.region);

		return address.join(", ");
	}, []);

	const productInfo = useMemo(() => {
		const info = [
			{ label: "Product Name", value: data.productName || "-" },
			{ label: "Insurance Provider", value: data.insuranceProviderName || "-" },
			{ label: "Customer Name", value: data.createdByName || "-" },
			{ label: "Organisation Name", value: data.ebCompany?.companyName || "-" },
			{ label: "Office No.", value: data.ebCompany?.mobileNoPrefix + data.ebCompany?.mobileNo || "-" },
			{ label: "Email Address", value: data.ebCompany?.email || "-" },
			{ label: "Organisation Address", value: companyAddress(data?.ebCompany) },
			{ label: "Business Reg. No.", value: data.ebCompany?.businessRegistrationNo || "-" },
			{ label: "Nature of Business", value: data.ebCompany?.natureOfBusiness || "-" },
		];
		return info;
	}, [companyAddress, data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.productEb.order(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-product-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="product-modal" aria-describedby="product-profile-modal">
			<div className="product-modal">
				<div className="product-modal__main">
					<button className="product-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="product-modal__header">
						<div className="product-modal__profile">
							<p className="product-modal__title">{data.orderNo}</p>
							<p className="product-modal__text">Order Submission Date: {formatDatePattern(data.createdDate)}</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="product-modal__text">Nett Annual Premium Price</p>
							<p className="product-modal__title">{formatCurrency(data.nettAnnualPremiumPrice)}</p>
						</div>
						<div className="info__box">
							<p className="product-modal__text">Status</p>
							<p className="product-modal__title">
								<AppStatus status={data.orderStatus} />
							</p>
						</div>
					</div>

					<div className="product-modal__tabs">
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.SUMMARY)} onClick={() => onHandleTabChange(TABS.SUMMARY)}>Summary</button>
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.DOCUMENTS)} onClick={() => onHandleTabChange(TABS.DOCUMENTS)}>Attached Documents</button>
					</div>

					{TABS.SUMMARY === tab && (
						<div className="product-modal__body">
							<div className="product-modal__container">
								<div className="product-modal__wrapper">
									{productInfo.map((o, i) => {
										return (
											<div className="product-modal__info" key={i}>
												<p className="product-modal__label">{o.label}</p>
												<p className="product-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>

							<div className="product-modal__container">
								<div className="product-modal__wrapper">
									<div className="product-modal__title">Plan Coverage</div>

									<div className="product-modal__content">
										{data.items?.map((a, i) => {
											const quantity = a.quantity || 0;

											return (
												<div className="product-modal__plans" key={i}>
													<div className="product-modal__plan-header">
														<p className="product-modal__label">{a.planName}</p>
														<p className="product-modal__value">{quantity} pax</p>
													</div>

													<div className="product-modal__plan-body">
														{a.benefits.map((b, j) => {
															const isGTL = b.productEbCode === COMMON.INSURANCE.PRODUCT_EB_CODE.GTL;
															const isGO = b.productEbCode === COMMON.INSURANCE.PRODUCT_EB_CODE.GO;
															const roomAndBoardCoverage = b.productEbPlanBenefitCoverages.find((c) => c.code === COMMON.INSURANCE.COVERAGES.ROOM_AND_BOARD);
															const optionalRiderPremiumAmount = b.productEbPlanBenefitRiders?.reduce((total, c) => total + c.premiumAmount, 0) || 0;

															return (
																<div className="product-modal__plan-content" key={j}>
																	<div className="product-modal__plan-wrapper">
																		<div className="product-modal__plan">
																			<p className="product-modal__label">{b.name}</p>

																			{roomAndBoardCoverage && (
																				<p className="product-modal__note">
																					• Daily Hospital Room & Board RM <b>{formatCurrency(roomAndBoardCoverage.coverageAmount)}</b>
																				</p>
																			)}

																			{!isGO && (
																				<p className="product-modal__note">
																					• Annual Limit RM <b>{formatCurrency(b.overallAnnualLimit)}</b>
																				</p>
																			)}
																		</div>

																		<div className="product-modal__price">
																			<p className="product-modal__total">RM {formatCurrency(b.premiumAmount * quantity)}</p>

																			<p className="product-modal__premium-amount">
																				{quantity} x RM {formatCurrency(b.premiumAmount)}
																			</p>
																		</div>
																	</div>

																	{isGTL && b.productEbPlanBenefitRiders && (
																		<Fragment>
																			<div className="product-modal__plan-wrapper">
																				<div className="product-modal__plan">
																					<p className="product-modal__label">Optional Rider for GTL</p>
																				</div>

																				<div className="product-modal__price">
																					<p className="product-modal__total">RM {formatCurrency(optionalRiderPremiumAmount * quantity)}</p>

																					<p className="product-modal__premium-amount">
																						{quantity} x RM {formatCurrency(optionalRiderPremiumAmount)}
																					</p>
																				</div>
																			</div>
																			<div className="product-modal__plans-gtl">
																				{b.productEbPlanBenefitRiders.map((c, k) => {
																					return (
																						<div className="product-modal__plan-wrapper" key={k}>
																							<div className="product-modal__coverage">
																								<p className="product-modal__note">• {c.name}</p>
																							</div>

																							<div className="product-modal__price">
																								<p className="product-modal__total product-modal__total--small">RM {formatCurrency(c.premiumAmount)}/Pax</p>
																							</div>
																						</div>
																					);
																				})}
																			</div>
																		</Fragment>
																	)}
																</div>
															);
														})}
													</div>
												</div>
											);
										})}

										<div className="product-modal__row">
											<p className="product-modal__label">Total No. of Employee</p>
											<p className="product-modal__value">{data.totalNoOfEmployee}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="product-modal__container">
								<p className="product-modal__title">Payment Details</p>

								<div className="product-modal__row">
									<p className="product-modal__label">Annual Premium Price</p>
									<p className="product-modal__total">RM {formatCurrency(data.annualPremiumPrice)}</p>
								</div>

								<div className="product-modal__row">
									<p className="product-modal__label">{`SST (${data.sst}%)`}</p>
									<p className="product-modal__total">RM {formatCurrency(data.sstAmount)}</p>
								</div>

								<div className="product-modal__row">
									<p className="product-modal__label">Stamp Duty</p>
									<p className="product-modal__total">RM {formatCurrency(data.stampDuty)}</p>
								</div>

								<div className="product-modal__row">
									<p className="product-modal__label">Payment Method</p>
									<p className="product-modal__total">TODO</p>
								</div>

								<div className="product-modal__row">
									<p className="product-modal__label">Bank Details</p>
									<p className="product-modal__total">
										{data.bankDetails?.accHolderName}
										<br />
										{data.bankDetails?.accNo}
										<br />
										{data.bankDetails?.bankName}
										<br />
									</p>
								</div>
							</div>
						</div>
					)}

					{TABS.DOCUMENTS === tab && (
						<div className="product-modal__body">
							<div className="product-modal__container">
								<div className="product-modal__docs-header">
									<p className="product-modal__label">File Name</p>
									<p className="product-modal__label">Uploaded</p>
									<p className="product-modal__label">File Size</p>
								</div>

								<ul className="product-modal__documents">
									{data.files?.map((a, i) => {
										return (
											<li className="product-modal__item" key={i}>
												<p className="product-modal__label">{a.fileName}</p>
												<p className="product-modal__label">
													<a className="product-modal__link" href={a.ossPath} target="_blank" rel="noreferrer">
														{a.fileName}
													</a>
												</p>
												<p className="product-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppProductModal));

AppProductModal.propTypes = {
	ref: PropTypes.object,
};
