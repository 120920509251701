import axios from "axios";

const services = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

const services_2 = axios.create({
	baseURL: process.env.REACT_APP_API_URL_2,
	headers: {
		"Content-Type": "application/json",
	},
});

export const testAPI = async () => {
    try {
        console.log('Attempting to connect to:', services_2.defaults.baseURL);
        const response = await services_2.get('/motor/public/motorManualEnquiryList');
        console.log('Connected:', response.status);
    } catch (error) {
        console.error('Connection Details:', {
            message: error.message,
            url: error.config?.url,
            baseURL: error.config?.baseURL,
            method: error.config?.method,
            code: error.code,
            isAxiosError: error.isAxiosError,
            response: error.response
        });
        
        // Specific error checks
        if (error.code === 'ERR_NETWORK') {
            console.log('🔴 Server might be down or wrong port/URL');
        } else if (error.code === 'ERR_BAD_REQUEST') {
            console.log('🔴 Invalid request (400)');
        } else if (error.response?.status === 403) {
            console.log('🔴 CORS or authentication issue');
        }
    }
};

export default services;
export { services_2 }
