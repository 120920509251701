const sanitizeObject = (obj) => {
	let sanitizedObject = {};

	if (!obj) return sanitizedObject;

	Object.keys(obj).forEach(function (key) {
		const value = obj[key];

		if (value !== null && value !== undefined && value !== "") {
			sanitizedObject[key] = value;
		}
	});

	return sanitizedObject;
};

export default sanitizeObject;
