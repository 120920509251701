import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import AppIcon from "components/app-icon";
import classNames from "common/class-names";
import checkerIcon from "assets/images/components/app-checkbox/checker-icon.svg";

const AppCheckbox = (props) => {
	const activated = useMemo(() => props.value, [props.value]);

	const className = useMemo(() => {
		return classNames({
			"app-checkbox": true,
			"app-checkbox--active": activated,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, activated]);

	return (
		<button className={className} type="button" name={props.name} disabled={props.disabled} value={activated || ""} onClick={props.onClick}>
			<div className="app-checkbox__box">
				<AppIcon src={checkerIcon} />
			</div>
			<p className="app-checkbox__label">{props.label}</p>
		</button>
	);
};

AppCheckbox.propTypes = {
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};

export default memo(AppCheckbox);
