import React, { memo, useRef, forwardRef, useImperativeHandle, useState, useCallback, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { formatCurrency } from "common/format-currency-pattern";
import { formatDatePattern, CALENDAR_FORMAT } from "common/calendar";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import AppDownloadPolicyModal from "components/pages/insurance-management/app-download-policy-modal";
import AppDownloadInvoiceModal from "components/pages/insurance-management/app-download-invoice-modal";
import AppDownloadPaymentReceiptModal from "components/pages/insurance-management/app-download-payment-receipt-modal";
import uploadIcon from "assets/images/upload-icon.svg";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

const TABS = [
	{ label: "Group Hospitalisation & Surgical", value: "GHS" },
	{ label: "Group Term Life", value: "GTL" },
	{ label: "Group Outpatient", value: "GO" },
];

const PAGES = {
	POLICY: "Policy Info",
	INSURER: "Insured Person(s)",
	PAYMENT_HISTORY: "Payment History",
	CREDIT: "Credit Note",
	DEBIT: "Debit Note",
};

const PAGE_OPTIONS = [
	{ label: "Policy Info", value: PAGES.POLICY },
	{ label: "Insured Person(s)", value: PAGES.INSURER },
	{ label: "Payment History", value: PAGES.PAYMENT_HISTORY },
	{ label: "Debit Note", value: PAGES.DEBIT },
	{ label: "Credit Note", value: PAGES.CREDIT },
];

const PAYMENT_TYPES = [
	{ label: "Debit Note", value: COMMON.POLICY.PAYMENT_TYPE.DEBIT_NOTE },
	{ label: "Credit Note", value: COMMON.POLICY.PAYMENT_TYPE.CREDIT_NOTE },
];

const invoiceMonthFormat = CALENDAR_FORMAT.MONTH_FORMAT;

const invoiceDateFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_SHORT_FORMAT;

const manufacturingYear = "2023";

const currentYear = new Date().getFullYear().toString();

export const AppPolicyModal = (props, ref) => {
	const navigate = useNavigate();
	const downloadPolicyRef = useRef();
	const downloadInvoiceRef = useRef();
	const downloadPaymentInvoiceRef = useRef();
	const [data, setData] = useState({});
	const [insurer, setInsurer] = useState();
	const [debit, setDebit] = useState();
	const [credit, setCredit] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [selectedDebitYear, setSelectedDebitYear] = useState(currentYear);
	const [selectedCreditYear, setSelectedCreditYear] = useState(currentYear);
	const [selectedPaymentHistory, setSelectedPaymentHistory] = useState(COMMON.POLICY.PAYMENT_TYPE.DEBIT_NOTE);
	const [selectedPaymentHistoryYear, setSelectedPaymentHistoryYear] = useState(currentYear);
	const [paymentHistory, setPaymentHistory] = useState();
	const [page, setPage] = useState(PAGES.POLICY);
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS[0].value);
	const planTabs = useMemo(() => TABS.filter((a) => data?.policies?.findIndex((b) => b.policyName === a.value) > -1), [data?.policies]);

	const policies = useMemo(() => {
		const sanitizedData = {};

		try {
			data?.policies?.forEach((o) => {
				if (!sanitizedData[o.policyName]) sanitizedData[o.policyName] = [];
				sanitizedData[o.policyName].push(o);
			});
		} catch (error) {
			serveRequestErrors(error);
		}

		return sanitizedData;
	}, [data]);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"policy-modal__tab": true,
			"policy-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const policyInfo = useMemo(() => {
		const info = [
			{ label: "Company Name", value: data.companyName },
			{ label: "Business Reg. No.", value: data.businessRegNo },
			{ label: "No. of Insured", value: data.insuredCount },
			{ label: "Effective Date", value: formatDatePattern(data.policyEffectiveDate) },
			{ label: "Expiry Date", value: formatDatePattern(data.policyExpiryDate) },
			{ label: "Insurance Provider", value: data.providerName },
			{ label: "Plan Name", value: data.productName },
		];
		return info;
	}, [data]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (obj) => {
		let response = null;

		try {
			const payload = { orderId: obj.orderId, renewalId: obj.renewalId };

			response = await api.get.policies.policy(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		setVisible(true);

		if (obj.orderId) onHandleGetDetails(obj);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setCredit();
		setInsurer();
		setVisible(false);
		setPage(PAGES.POLICY);
	}, []);

	//prettier-ignore
	const onHandleGetCreditNote = useCallback(async (creditYear) => {
	let response = null;

	try {
		const payload = { orderId: data.orderId, renewalId: data.renewalId };

		response = await api.get.policies.creditNote({ ...payload, year: creditYear });
	} catch (error) {
		serveRequestErrors(error);
	}

	if (response) {
		const sanitizedData = {};

		response.forEach((o) => {
			const month = formatDatePattern(o.invoiceDate, invoiceMonthFormat);

			if (!sanitizedData[month]) sanitizedData[month] = [];

			sanitizedData[month].push(o);
		});

		setCredit(sanitizedData);
	}
}, [data]);

	//prettier-ignore
	const onHandleGetDebitNote = useCallback(async (debitYear) => {
	let response = null;

	try {
		const payload = { orderId: data.orderId, renewalId: data.renewalId };

		response = await api.get.policies.debitNote({ ...payload, year: debitYear });
	} catch (error) {
		serveRequestErrors(error);
	}

	if (response) {
		const sanitizedData = {};

		response.forEach((o) => {
			const month = formatDatePattern(o.invoiceDate, invoiceMonthFormat);

			if (!sanitizedData[month]) sanitizedData[month] = [];

			sanitizedData[month].push(o);
		});

		setDebit(sanitizedData);
	}
}, [data]);

	const onHandleRefundCredit = useCallback(async () => {}, []);

	//prettier-ignore
	const onHandleGetPaymentHistory = useCallback(async (year, type) => {
		setSubmitting(true);

		let response = null;

		try {
			if(type === COMMON.POLICY.PAYMENT_TYPE.CREDIT_NOTE) {
				response = await api.get.policies.creditPaymentHistory(`${data.orderId}/${year}`);
			}
			else {
				response = await api.get.policies.debitPaymentHistory(`${data.orderId}/${year}`);
			}
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedData = {};

			response.forEach((o) => {
				const month = formatDatePattern(o.createdDate, invoiceMonthFormat);

				if (!sanitizedData[month]) sanitizedData[month] = {};

				if (!sanitizedData[month][o.refNo]) sanitizedData[month][o.refNo] = [];

				sanitizedData[month][o.refNo].push(o);
			});

			setPaymentHistory(sanitizedData);
		}
	}, [data]);

	//prettier-ignore
	const onHandleGetInsurer = useCallback(async (payload) => {
		let response = null;

		try {
			response = await api.get.policies.insurer(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedInsured = { parent: {}, subsidiary: {} };

			response.forEach((o) => {
				const type = o.isParentCompany ? "parent" : "subsidiary";

				if (!sanitizedInsured[type][o.companyName]) {
					sanitizedInsured[type][o.companyName] = {};
				}

				if (!sanitizedInsured[type][o.companyName][o.ebInsurancePlan]) {
					sanitizedInsured[type][o.companyName][o.ebInsurancePlan] = [];
				}

				sanitizedInsured[type][o.companyName][o.ebInsurancePlan].push(o);
			});

			setInsurer(sanitizedInsured);
		}
	}, []);

	//prettier-ignore
	const onHandleChangePage = useCallback((event) => {
		const payload = { orderId: data.orderId, renewalId: data.renewalId };

		const value = event.target.value;

		switch (value) {
			case PAGES.POLICY:
				onHandleGetDetails(payload);
			break;
			case PAGES.PAYMENT_HISTORY:
				onHandleGetPaymentHistory(currentYear, selectedPaymentHistory);
			break;
			case PAGES.INSURER:
				onHandleGetInsurer(payload);
				break;
			case PAGES.CREDIT:
				setSelectedCreditYear(currentYear);
				onHandleGetCreditNote(currentYear);
				break;
			case PAGES.DEBIT:
				setSelectedDebitYear(currentYear);
				onHandleGetDebitNote(currentYear);
				break;
			default:
				break;
		}

		setPage(value);
	}, [onHandleGetDetails, onHandleGetInsurer, onHandleGetCreditNote, onHandleGetDebitNote, onHandleGetPaymentHistory, data, selectedPaymentHistory]);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	const onHandleDownloadPolicy = useCallback((obj, currentTab) => {
		const currentPolicy = TABS.find((o) => o.value === currentTab);
		downloadPolicyRef.current.onHandleShow(obj, currentPolicy);
	}, []);

	const onHandleMakePayment = useCallback(() => {}, []);

	const onHandleDownloadInvoice = useCallback((obj) => {
		downloadInvoiceRef.current.onHandleShow(obj);
	}, []);

	const onHandleDownloadPaymentReceipt = useCallback((obj, paymentHistoryType) => {
		downloadPaymentInvoiceRef.current.onHandleShow(obj, paymentHistoryType);
	}, []);

	const onHandleManageInsurer = useCallback(() => {
		navigate(pathnames.employees);
	}, [navigate]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	const PolicyInfo = useCallback((obj) => {
		if (obj.page !== PAGES.POLICY) return null;

		return (
			<div className="policy-modal__scrollable">
				<div className="policy-modal__body">
					<div className="policy-modal__container">
						{obj.policyInfo.map((a, i) => {
							return (
								<div className="policy-modal__info" key={i}>
									<p className="policy-modal__label">{a.label}</p>
									<p className="policy-modal__value">{a.value || "-"}</p>
								</div>
							);
						})}
					</div>
				</div>

				{Boolean(obj.tabs?.length) && <AppScrollableTabs tabs={obj.tabs} onChange={setTab} />}

				{!obj.policy && (
					<div className="policy-modal__loading">
						<LoadingLogo />
					</div>
				)}

				{obj.policy?.map?.((a) => {
					return (
						<Fragment key={a.policyNo}>
							<div className="policy-modal__body">
								<div className="policy-modal__container">
									<div className="policy-modal__info">
										<p className="policy-modal__label">Policy No.</p>
										<p className="policy-modal__value">{a.policyNo}</p>
									</div>
								</div>
							</div>

							<div className="policy-modal__divider" />

							<div className="plan">
								<div className="plan__header">
									<p className="plan__title">{a.policyFullName}</p>

									<AppButton type="button" outline label="Download Policy" icon={uploadIcon} onClick={() => obj.onHandleDownloadPolicy({ ...obj.data, policyNo: a.policyNo }, obj.tab)} />
								</div>
							</div>

							{a?.planCoverage.map((b) => {
								return (
									<div className="box" key={b.internalCode}>
										<div className="box__container">
											<div className="box__header">
												<p className="box__headline">{b.planName}</p>
												<p className="box__sub-headline">{b.quantity} pax</p>
											</div>

											<div className="box__divider" />

											<ul className="box__list">
												<div className="box__coverage">
													<p className="box__sub-headline">Overall Annual Limit</p>
													<p className="box__sub-headline">RM {formatCurrency(b.benefit?.overallAnnualLimit)}</p>
												</div>

												{b.benefit?.productEbPlanBenefitCoverages?.map((c) => {
													return (
														<li className="box__coverage" key={c.name}>
															<p className="box__benefit">{c.name}</p>
															<p className="box__benefit">{c.coverageAmount ? `RM ${formatCurrency(c.coverageAmount)}` : c.coverageAmount === 0 ? "As Charged" : ""}</p>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								);
							})}
						</Fragment>
					);
				})}
			</div>
		);
	}, []);

	const InsurerInfo = useCallback((obj) => {
		if (obj.page !== PAGES.INSURER) return null;

		if (!obj.insurer) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<div className="policy-modal__scrollable">
				{Object.keys(obj.insurer).map((a) => {
					const plans = obj.insurer[a];

					return Object.keys(plans).map((b) => {
						const plan = plans[b];
						const pax = Object.keys(plan).reduce((j, k) => (j += plan[k].length), 0);

						return (
							<Fragment key={a}>
								<div className="plan">
									<div className="plan__header">
										<p className="plan__title">{a.toUpperCase()} COMPANY</p>
									</div>
								</div>

								<div className="box">
									<div className="box__container">
										<div className="box__header">
											<p className="box__headline">{b}</p>
											<p className="box__sub-headline">{pax} pax</p>
										</div>

										<div className="box__divider" />

										<div className="box__body">
											{Object.keys(plan).map((c) => {
												return (
													<Fragment key={c}>
														<ul className="box__list">
															<li className="box__insurer">
																<p className="box__title">{capitalizeCharacter(c.split("_").join(" "))}</p>
															</li>

															{plan[c].map((d) => {
																return (
																	<li key={d.email} className="box__insurer">
																		<div className="box__avatar" style={{ backgroundImage: `url(${d.avatar})` }} />

																		<div className="box__center">
																			<p className="box__headline">{d.name}</p>
																			<p className="box__sub-headline">{d.email}</p>
																		</div>

																		<p className="box__text">{d.employeeId || "-"}</p>
																	</li>
																);
															})}
														</ul>
													</Fragment>
												);
											})}
										</div>
									</div>
								</div>
							</Fragment>
						);
					});
				})}
			</div>
		);
	}, []);

	const CreditNoteInfo = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.CREDIT) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedCreditYear(value);
			obj.onHandleGetCreditNote(value);
		};

		const onHandleColors = (status) => {
			let color = "rgb(0, 207, 156)";
			switch (status) {
				case COMMON.STATUS_ID.UNCLAIMED:
					color = "rgb(255, 199, 112)";
					break;
				case COMMON.STATUS_ID.REFUND_IN_PROGRESS:
					color = "#0245A9";
					break;
				default:
					break;
			}

			return color;
		};

		if (!obj.creditNote) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<div className="policy-modal__scrollable">
				<div className="credit-debit-note">
					<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedCreditYear} options={yearsOption} onChange={onHandleSelectYear} />

					<div className="history">
						{Object.keys(obj.creditNote).map((month) => {
							const item = obj.creditNote[month];

							return (
								<div className="history__container" key={month}>
									<div className="history__header">
										<p className="history__month">{month}</p>
									</div>

									{item.map((o, i) => {
										const firstItem = i === 0;
										const checkpointVisible = (!firstItem && o.status !== item[i - 1].status) || firstItem;
										const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });

										return (
											<div className="history__body" key={o.id}>
												{checkpointVisible && <div className="history__checkpoint" style={{ backgroundColor: onHandleColors(o.status) }} />}
												<div className={checkboxLineClassName} />

												<div className="history__start">
													<p className="history__invoice-no">{o.invoiceNo}</p>
													<p className="history__date">{formatDatePattern(o.invoiceDate, invoiceDateFormat)}</p>
												</div>

												<div className="history__center">
													<p className="history__amount">RM {formatCurrency(o.invoiceAmount)}</p>
													<AppStatus status={o.status} />
												</div>

												<div className="history__end">
													<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadInvoice(o)}>
														<img className="history__download-icon" src={uploadIcon} alt="download" />
													</button>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}, []);

	const DebitNoteInfo = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - +manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.DEBIT) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedDebitYear(value);
			obj.onHandleGetDebitNote(value);
		};

		const onHandleColors = (status) => {
			let color = "rgb(0, 207, 156)";
			switch (status) {
				case COMMON.STATUS_ID.UNPAID:
					color = "rgb(255, 199, 112)";
					break;
				case COMMON.STATUS_ID.PAID:
					color = "#0245A9";
					break;
				default:
					break;
			}

			return color;
		};

		if (!obj.debitNote) {
			return (
				<div className="policy-modal__loading">
					<LoadingLogo />
				</div>
			);
		}

		return (
			<div className="policy-modal__scrollable">
				<div className="credit-debit-note">
					<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedDebitYear} options={yearsOption} onChange={onHandleSelectYear} />

					<div className="history">
						{Object.keys(obj.debitNote).map((month) => {
							const item = obj.debitNote[month];

							return (
								<div className="history__container" key={month}>
									<div className="history__header">
										<p className="history__month">{month}</p>
									</div>

									{item.map((o, i) => {
										const firstItem = i === 0;
										const checkpointVisible = (!firstItem && o.status !== item[i - 1].status) || firstItem;
										const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });

										return (
											<div className="history__body" key={o.id}>
												{checkpointVisible && <div className="history__checkpoint" style={{ backgroundColor: onHandleColors(o.status) }} />}
												<div className={checkboxLineClassName} />

												<div className="history__start">
													<p className="history__invoice-no">{o.invoiceNo}</p>
													<p className="history__date">{formatDatePattern(o.invoiceDate, invoiceDateFormat)}</p>
												</div>

												<div className="history__center">
													<p className="history__amount">RM {formatCurrency(o.invoiceAmount)}</p>
													<AppStatus status={o.status} />
												</div>

												<div className="history__end">
													<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadInvoice(o)}>
														<img className="history__download-icon" src={uploadIcon} alt="download" />
													</button>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}, []);

	const PaymentHistory = useCallback((obj) => {
		const yearsOption = Array.from({ length: +currentYear + 1 - +manufacturingYear }, (_, i) => +currentYear - i).map((o) => ({ label: o.toString(), value: o.toString() }));

		if (obj.page !== PAGES.PAYMENT_HISTORY) return null;

		const onHandleSelectYear = (event) => {
			const value = event.target.value;
			setSelectedPaymentHistoryYear(value);
			obj.onHandleGetPaymentHistory(value, obj.selectedPaymentHistory);
		};

		const onHandleSelectPaymentHistory = (event) => {
			const value = event.target.value;
			setSelectedPaymentHistory(value);
			obj.onHandleGetPaymentHistory(obj.selectedYear, value);
		};

		return (
			<div className="policy-modal__scrollable">
				<div className="payment-history">
					<div className="payment-history__header">
						<AppSelectInput className="payment-history__type-select-input" searchable={false} label="" placeholder="Please Select" value={obj.selectedPaymentHistory} options={PAYMENT_TYPES} onChange={onHandleSelectPaymentHistory} />

						<AppSelectInput searchable={false} label="" placeholder="Please Select" value={obj.selectedYear} options={yearsOption} onChange={onHandleSelectYear} />
					</div>

					{!obj.paymentHistory && (
						<div className="policy-modal__loading">
							<LoadingLogo />
						</div>
					)}

					{obj.paymentHistory && (
						<div className="history">
							{Object.keys(obj.paymentHistory).map((month) => {
								const items = obj.paymentHistory[month];

								return (
									<div className="history__container" key={month}>
										<div className="history__header">
											<p className="history__month">{month}</p>
										</div>

										{Object.keys(items).map((a) => {
											const item = items[a];

											return item.map((b, i) => {
												const firstItem = i === 0;
												const checkpointVisible = (!firstItem && b.status !== item[i - 1].status) || firstItem;
												const checkboxLineClassName = classNames({ "history__checkpoint-line": true, "history__checkpoint-line--extend": !checkpointVisible });
												const total = item.reduce((o, j) => o + j.invoices.reduce((k, l) => k + l.invoiceAmount, 0), 0);

												return (
													<Fragment key={b.id}>
														<div className="history__body">
															<div className="history__checkpoint" />
															<div className={checkboxLineClassName} />

															<div className="history__start">
																<p className="history__invoice-no">{b.trxNo || b.refNo}</p>
																<p className="history__date">{formatDatePattern(b.createdDate, invoiceDateFormat)}</p>
															</div>

															<div className="history__center">
																<p className="history__amount">RM {formatCurrency(total)}</p>
																<AppStatus status={b.status || b.invoiceStatus} />
															</div>

															<div className="history__end">
																<button type="button" className="history__download-button" onClick={() => obj.onHandleDownloadPaymentReceipt(b, obj.selectedPaymentHistory)}>
																	<img className="history__download-icon" src={uploadIcon} alt="download" />
																</button>
															</div>
														</div>
														<div className="history__body">
															<div className="history__checkpoint-line history__checkpoint-line--extend" />

															<ul className="history__list">
																{b.invoices.map((c) => {
																	return (
																		<li key={c.invoiceNo} className="history__item">
																			<div className="history__start">
																				<p className="history__invoice-no">{c.invoiceNo}</p>
																				<p className="history__date">{formatDatePattern(c.invoiceDate, invoiceDateFormat)}</p>
																			</div>

																			<div className="history__center">
																				<p className="history__invoice-amount">RM {formatCurrency(c.invoiceAmount)}</p>
																			</div>
																		</li>
																	);
																})}
															</ul>
														</div>
													</Fragment>
												);
											});
										})}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}, []);

	return (
		<Fragment>
			<Modal classes={{ root: "app-policy-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="policy-modal" aria-describedby="policy-modal">
				<div className="policy-modal">
					<div className="policy-modal__main">
						<button className="policy-modal__close-button" onClick={onHandleDismiss}>
							<AppCloseIcon color="#666666" />
						</button>

						<div className="policy-modal__header">
							<p className="policy-modal__title">{data.productName}</p>
						</div>

						<div className="info">
							<div className="info__box">
								<p className="policy-modal__text">Nett Annual Premium Price</p>
								<p className="policy-modal__amount">RM {formatCurrency(data.nettAnnualPremiumPrice)}</p>
							</div>
							<div className="info__box">
								<p className="policy-modal__text">Status</p>
								<div className="policy-modal__title">
									<AppStatus status={data?.status || "-"} />
								</div>
							</div>
						</div>

						{/* prettier-ignore */}
						<AppSelectInput searchable={false} type="text" name="page" placeholder="Please Select" options={PAGE_OPTIONS} value={page} onChange={onHandleChangePage} />

						{/* prettier-ignore */}
						<PolicyInfo tabs={planTabs} page={page} tab={tab} data={data} policy={policies?.[tab]} policyInfo={policyInfo} tabClassName={tabClassName} onHandleTabChange={onHandleTabChange} onHandleDownloadPolicy={onHandleDownloadPolicy} />

						<InsurerInfo page={page} insurer={insurer} onHandleManageInsurer={onHandleManageInsurer} />

						{/* prettier-ignore */}
						<CreditNoteInfo page={page} selectedCreditYear={selectedCreditYear} creditNote={credit} submitting={submitting} onHandleRefundCredit={onHandleRefundCredit} onHandleGetCreditNote={onHandleGetCreditNote} onHandleDownloadInvoice={onHandleDownloadInvoice} />

						{/* prettier-ignore */}
						<DebitNoteInfo page={page} selectedDebitYear={selectedDebitYear} debitNote={debit} submitting={submitting} onHandleGetDebitNote={onHandleGetDebitNote} onHandleDownloadInvoice={onHandleDownloadInvoice} onHandleMakePayment={onHandleMakePayment} />

						{/* prettier-ignore */}
						<PaymentHistory page={page} selectedYear={selectedPaymentHistoryYear} selectedPaymentHistory={selectedPaymentHistory} paymentHistory={paymentHistory} onHandleGetPaymentHistory={onHandleGetPaymentHistory} onHandleDownloadPaymentReceipt={onHandleDownloadPaymentReceipt}/>
					</div>
				</div>
			</Modal>

			<AppDownloadPolicyModal ref={downloadPolicyRef} />

			<AppDownloadInvoiceModal ref={downloadInvoiceRef} />

			<AppDownloadPaymentReceiptModal ref={downloadPaymentInvoiceRef} />
		</Fragment>
	);
};

export default memo(forwardRef(AppPolicyModal));

AppPolicyModal.propTypes = {
	ref: PropTypes.object,
};
