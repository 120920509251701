import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, Fragment, useMemo, useEffect } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import AppCheckbox from "components/app-checkbox";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import AppButton from "components/app-button";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppDownloadPolicyModal = (props, ref) => {
	const [policy, setPolicy] = useState({});
	const [visible, setVisible] = useState(false);
	const [documents, setDocuments] = useState();
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [selectedPolicy, setSelectedPolicy] = useState([]);
	const selectedAll = useMemo(() => {
		let total = 0;

		if (documents?.files) {
			Object.keys(documents.files).forEach((o) => {
				total += documents.files[o].length;
			});
		}

		return selectedPolicy?.length === total;
	}, [selectedPolicy, documents]);

	const onHandleGetDocuments = useCallback(async (obj, policyPlan) => {
		setPolicy({ ...policyPlan, ...obj });

		let response = null;

		try {
			const payload = { orderId: obj.orderId, policyName: policyPlan.value, renewalId: obj.renewalId };
			response = await api.get.policies.documents(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedDocuments = {};

			response.forEach((o) => {
				if (!sanitizedDocuments.files) {
					sanitizedDocuments.files = {};
				}

				if (!sanitizedDocuments.files[o.category]) {
					sanitizedDocuments.files[o.category] = [];
				}

				sanitizedDocuments.policyNo = o.policyNo;
				sanitizedDocuments.policyFullName = o.policyFullName;
				sanitizedDocuments.files[o.category].push(o);
			});

			setDocuments(sanitizedDocuments);
		}
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj, plan) => {
		setVisible(true);
		onHandleGetDocuments(obj, plan);
	}, [onHandleGetDocuments]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setSelectedPolicy([]);
		setDownloadProgress(0);
		setDocuments();
	}, []);

	//prettier-ignore
	const onHandleSelectPolicy = useCallback((event) => {
        const name = event.target.name;
        const isSelectedAllField = name === "all";

        if (isSelectedAllField) {
            let next = [];

            if(!selectedAll) next = Object.keys(documents.files)?.map((a) => documents.files[a].map(b => b.id.toString())).flat();
            
            setSelectedPolicy(next);
        } else {
            setSelectedPolicy((prev) => {
                const current = [...prev];
                const found = current.findIndex((o) => o === name) > -1;
                const next = current.filter((o) => o !== name);

                if (!found) next.push(name);

                return next;
            });
        }
    }, [documents, selectedAll]);

	const onHandleDownloadDocuments = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			const payload = { policyNo: policy.policyNo, policyFileIds: selectedPolicy, renewalId: policy.renewalId };
			response = await api.post.policies.downloadDocuments(payload, { onDownloadProgress, transformResponse });
		} catch (error) {
			setDownloadProgress(0);
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
			}, 1000);
		}
	}, [policy, selectedPolicy]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".download-policy__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (documents?.files) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [documents]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-download-policy-modal" }} open={visible} aria-labelledby="download-policy" aria-describedby="download-policy-modal">
			<div className="download-policy">
				<div className="download-policy__header">
					<p className="download-policy__title">{policy.label}</p>
					<p className="download-policy__description">Please select document to download</p>
				</div>

				{!documents?.files && (
					<div className="download-policy__loading">
						<LoadingLogo />
					</div>
				)}

				{documents?.files && (
					<ul className="download-policy__list">
						<div className="download-policy__list-header">
							All
							<AppCheckbox label="" onClick={onHandleSelectPolicy} name="all" value={selectedAll} />
						</div>

						{Object.keys(documents.files).map((a) => {
							const item = documents.files[a];
							return (
								<Fragment key={a}>
									<p className="download-policy__category">{capitalizeCharacter(a)}</p>

									{item.map((b) => {
										const id = b.id.toString();
										const found = selectedPolicy?.findIndex((c) => c === id) > -1;

										return (
											<li className="download-policy__item" key={b.id}>
												{b.fileName}
												<AppCheckbox label="" onClick={onHandleSelectPolicy} name={id} value={found} />
											</li>
										);
									})}
								</Fragment>
							);
						})}
					</ul>
				)}

				<div className="download-policy__progress-bar" style={{ width: `${downloadProgress}%` }} />

				<div className="download-policy__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Download" onClick={onHandleDownloadDocuments} disabled={!!downloadProgress || !selectedPolicy.length} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDownloadPolicyModal));

AppDownloadPolicyModal.propTypes = {
	ref: PropTypes.object,
};
