import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

export const AppEndConversationModal = (props, ref) => {
	const [isSubmitting, setIsSubmitting] = useState();
	const [visible, setVisible] = useState(false);
	const [conversationId, setConversationId] = useState({});

	const onHandleShow = useCallback((id) => {
		setConversationId(id);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleDelete = async () => {
		let response = null;
		setIsSubmitting(true);

		try {
			await api.post.contactUs.endConversation(conversationId);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}

		if (response) {
			onHandleDismiss();
			props.onHandleSetData((prev) => {
				const previous = { ...prev, items: prev.items.map((o) => ({ ...o, status: o.id === conversationId ? COMMON.STATUS_ID.CONVERSATION_ENDED : o.status })) };
				return previous;
			});
			props.onHandleMessage({ id: conversationId });
		}
	};

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-end-conversation-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="end-conversation-modal" aria-describedby="end-conversation-modal">
			<div className="end-conversation-modal">
				<div className="end-conversation-modal__main">
					<div className="end-conversation-modal__header">
						<p className="end-conversation-modal__title">End Conversation</p>
					</div>

					<div className="end-conversation-modal__body">
						<p className="end-conversation-modal__description">Are you sure want to end this conversation?</p>

						<div className="end-conversation-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="button" label="Confirm" onClick={onHandleDelete} disabled={isSubmitting} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEndConversationModal));

AppEndConversationModal.propTypes = {
	ref: PropTypes.object,
};
