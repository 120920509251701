const encodeQueryParams = (data) => {
	if (!data) return "";

	if (typeof data !== "object") return data;

	const query = [];
	for (let q in data) {

		if(data[q] !== null && data[q] !== undefined) query.push(encodeURIComponent(q) + "=" + encodeURIComponent(data[q]));

	}

	return "?" + query.join("&");
};

export default encodeQueryParams;
