import React, { useContext, useCallback, useMemo, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import ERRORS from "common/errors";
import api from "services/api";
import pathnames from "routes/pathnames";
import stringEncryptRSA from "common/string-encrypt-rsa";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckIcon from "components/icons/app-check-icon";
import logo from "assets/images/logo.webp";

const PageResetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const props = useContext(AxiosContext);
	const [searchParams] = useSearchParams();
	const memoToken = useMemo(() => searchParams.get("token"), [searchParams]);
	const memoEmail = useMemo(() => searchParams.get("email"), [searchParams]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	//prettier-ignore
	const formik = useFormik({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validationSchema: yup.object({
            password: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(COMMON.REGEX.UPPERCASE, ERRORS.REQUIRED).matches(COMMON.REGEX.LOWERCASE, ERRORS.REQUIRED).matches(COMMON.REGEX.SYMBOL, ERRORS.REQUIRED).matches(COMMON.REGEX.NUMERIC, ERRORS.REQUIRED),
			confirmPassword: yup.string().required(ERRORS.REQUIRED).oneOf([yup.ref("password"), null], ERRORS.CONFIRM_PASSWORD),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = async (values) => {
		try {
			if (memoEmail && memoToken) {
				await api.post.authenticate.resetPassword({ password: stringEncryptRSA(values.password), email: memoEmail, token: memoToken });
			} else {
				await api.post.authenticate.updatePassword({ password: stringEncryptRSA(values.password) });

				dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
				localStorage.clear();
				sessionStorage.clear();
			}

			navigate(pathnames.resetPasswordSuccess);
		} catch (error) {
			serveRequestErrors(error, (msg) => {
				formik.setFieldError("confirmPassword", msg);
			});
		} finally {
			formik.setSubmitting(false);
		}
	};

	const onHandleBack = () => {
		localStorage.clear();
		sessionStorage.clear();
		dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
	};

	const onHandleGetValidationStatus = useCallback(() => {
		const value = formik.values.password;
		const touched = formik.touched.password;
		const length = 8;

		const requirement = [
			{
				valid: value.length >= length,
				label: ERRORS.LENGTH,
			},
			{
				valid: value.match(COMMON.REGEX.UPPERCASE),
				label: ERRORS.UPPERCASE,
			},
			{
				valid: value.match(COMMON.REGEX.LOWERCASE),
				label: ERRORS.LOWERCASE,
			},
			{
				valid: value.match(COMMON.REGEX.NUMERIC),
				label: ERRORS.NUMERIC,
			},
			{
				valid: value.match(COMMON.REGEX.SYMBOL),
				label: ERRORS.SYMBOL,
			},
		];

		const validationItem = requirement.map((item, index) => {
			const initStastus = !value && !touched;
			const icon = item.valid && !initStastus ? <AppCheckIcon color="#00CF9C" /> : <AppCheckIcon color="#C0C0C0" />;

			return (
				<p key={index} className="main__description">
					<span className="main__icon">{icon}</span>
					{item.label}
				</p>
			);
		});

		return validationItem;
	}, [formik]);

	useEffect(() => {
		const onHandleValidateToken = async () => {
			if (!memoToken || !memoEmail) return;

			try {
				await api.post.authenticate.verifyForgotPassword({ token: memoToken, email: memoEmail });
			} catch (error) {
				serveRequestErrors(error, () => {
					navigate(pathnames.forgotPasswordExpired);
				});
			}
		};

		onHandleValidateToken();
	}, [navigate, memoEmail, memoToken]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AUTHENTICATE.VERIFY_FORGOT_PASSWORD);
		};
	}, [cancelRequest]);

	return (
		<div className="page-reset-password">
			<div className="reset-password">
				<main className="main">
					<div className="main__content main__content--background">
						<Link to={pathnames.login} className="main__logo" onClick={onHandleBack}>
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div className="main__content main__content--form">
						<form className="main__form" onSubmit={formik.handleSubmit}>
							<h1 className="main__title">Reset Password</h1>
							{/* prettier-ignore */}
							<AppInput type="password" name="password" label="New Password" placeholder="New Password" error={formik.errors.password} touched={formik.touched.password} disabled={formik.isSubmitting} onChange={formik.handleChange} />

							<div className="main__guidence">{onHandleGetValidationStatus()}</div>

							{/* prettier-ignore */}
							<AppInput type="password" name="confirmPassword" label="Confirm New Password" placeholder="Confirm New Password" error={formik.errors.password} touched={formik.touched.password} disabled={formik.isSubmitting} onChange={formik.handleChange} />

							<div className="main__button-container">
								<AppButton type="submit" label="Reset" disabled={formik.isSubmitting || !formik.isValid} />
							</div>
						</form>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageResetPassword;
