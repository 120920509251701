import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import AppTabs from "components/app-tabs";
import AppPaymentApplicationTable from "components/pages/audit-log/app-payment-application-table";
import AppPaymentInvoiceTable from "components/pages/audit-log/app-payment-invoice-table";

const TAB = {
	APPLICATION: "APPLICATION",
	INVOICE: "INVOICE",
};

const tabs = [
	{ label: "EB Application", value: TAB.APPLICATION },
	{ label: "EB Invoice", value: TAB.INVOICE },
];

const PagePayment = (props) => {
	const [searchParams] = useSearchParams();
	const [tab, setTab] = useState(searchParams.get("tab") || TAB.APPLICATION);
	const isApplicationTab = useMemo(() => tab === TAB.APPLICATION, [tab]);
	const isInvoiceTab = useMemo(() => tab === TAB.INVOICE, [tab]);
	const onHandleCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleTabChange = (currentTab) => {
		setTab(currentTab);
	};

	return (
		<div className="page-payment">
			<div className="payment">
				<AppTabs value={tab} tabs={tabs} onChange={onHandleTabChange} />

				{isApplicationTab && <AppPaymentApplicationTable onHandleCancelRequest={onHandleCancelRequest} />}

				{isInvoiceTab && <AppPaymentInvoiceTable onHandleCancelRequest={onHandleCancelRequest} />}
			</div>
		</div>
	);
};

export default PagePayment;
