import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import converReadableFileSize from "common/convert-readable-file-size";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";

const TAB = {
	SUMMARY: "SUMMARY",
	REMARKS: "REMARKS",
	DOCUMENTS: "DOCUMENTS",
	CLAIM_SETTLEMENT_DOCUMENT: "CLAIM_SETTLEMENT_DOCUMENT",
};
const TABS = [
	{ label: "Summary", value: TAB.SUMMARY },
	{ label: "Uploaded Documents", value: TAB.DOCUMENTS },
	{ label: "Claim Settlement Document", value: TAB.CLAIM_SETTLEMENT_DOCUMENT },
	{ label: "Remarks", value: TAB.REMARKS },
];

export const AppClaimModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS.SUMMARY);

	const claimInfo = useMemo(() => {
		const info = [
			{ label: "Claim Ref. No.", value: data.refNo || "-" },
			{ label: "Policy No.", value: data.policyNo || "-" },
			{ label: "Claimant Name", value: data.name || "-" },
			{ label: "Claimant ID", value: data.nric ? formatStringPattern(data.nric, COMMON.MASKING.NRIC) : data.passport ? data.passport : "-" },
			{ label: "Type of Claim", value: data.typeOfClaim || "-" },
		];
		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		onHandleGetDetails(id);

		setVisible(true);
	}, []);

	const onHandleGetDetails = async (id) => {
		let response = null;

		try {
			response = await api.get.claims.claim(id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response);
	};

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-claim-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="claim-modal" aria-describedby="claim-profile-modal">
			<div className="claim-modal">
				<div className="claim-modal__main">
					<button className="claim-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="claim-modal__header">
						<div className="claim-modal__profile">
							<p className="claim-modal__title">{data.refNo}</p>
							<p className="claim-modal__text">Claim Submission Date: {formatDatePattern(data.createdDate)}</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="claim-modal__text">Employee ID</p>
							<p className="claim-modal__title">{data.employeeId || "-"}</p>
						</div>
						<div className="info__box">
							<p className="claim-modal__text">Status</p>
							<p className="claim-modal__title">
								<AppStatus status={data.status} />
							</p>
						</div>
					</div>

					<AppScrollableTabs tabs={TABS} onChange={setTab} />

					{TAB.SUMMARY === tab && (
						<div className="claim-modal__body">
							<div className="claim-modal__container">
								<div className="claim-modal__wrapper">
									{claimInfo.map((o, i) => {
										return (
											<div className="claim-modal__info" key={i}>
												<p className="claim-modal__label">{o.label}</p>
												<p className="claim-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}

					{TAB.DOCUMENTS === tab && (
						<div className="claim-modal__body">
							<div className="claim-modal__container">
								<div className="claim-modal__docs-header">
									<p className="claim-modal__label">Uploaded</p>
									<p className="claim-modal__label">File Size</p>
								</div>

								<ul className="claim-modal__documents">
									{data.docs?.map((a, i) => {
										return (
											<li className="claim-modal__item" key={i}>
												<p className="claim-modal__label">
													<a className="claim-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
														{a.fileName}
													</a>
												</p>
												<p className="claim-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					)}

					{TAB.CLAIM_SETTLEMENT_DOCUMENT === tab && (
						<div className="claim-modal__body">
							<div className="claim-modal__container">
								<div className="claim-modal__docs-header">
									<p className="claim-modal__label">Uploaded</p>
									<p className="claim-modal__label">File Size</p>
								</div>

								<ul className="claim-modal__documents">
									{data.settlementDocs?.map((a, i) => {
										return (
											<li className="claim-modal__item" key={i}>
												<p className="claim-modal__label">
													<a className="claim-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
														{a.fileName}
													</a>
												</p>
												<p className="claim-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					)}

					{TAB.REMARKS === tab && (
						<div className="claim-modal__body">
							<div className="claim-modal__container">
								<p className="claim-modal__value">{data.remarks || "-"}</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppClaimModal));

AppClaimModal.propTypes = {
	ref: PropTypes.object,
};
