import React, { memo, useImperativeHandle, forwardRef, useMemo, useState, useCallback } from "react";
import Menu from "@mui/material/Menu";
import PropTypes from "prop-types";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import searchIcon from "assets/images/search-icon.svg";
import AppCloseIcon from "components/icons/app-close-icon";

//prettier-ignore
const AppSearchInput = ({ children, ...props }, ref) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const isMultiValues = useMemo(() => props.multiValues && Object.keys(props.multiValues).some(o => props.multiValues[o]), [props.multiValues]);

	const onhandleCloseAdvanceSearch = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleShowAdvanceSearch = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const onHandleChange = useCallback((event) => {
		props.onChange(event);
	}, [props]);

	const onHandleRemoveField = useCallback((event, field) => {
		event.stopPropagation();
		props.onRemoveField(field);
	}, [props])

	const Pills = useCallback(() => {
		if(!props.multiValues) return null;

		const items = Object.keys(props.multiValues).map((o, i) => {
			if(!props.multiValues[o]) return null;

			return (
				<div className="advance-search__pill" key={i}>
					<p className="advance-search__value">{props.multiValues[o]} </p>
					<button className="advance-search__remove" onClick={(event) => onHandleRemoveField(event, o)}>
						<AppCloseIcon color="#ffffff"/> 
					</button>
				</div> 
			);
		});

		return items;
	}, [props.multiValues, onHandleRemoveField]);

	useImperativeHandle(ref, () => ({
		onhandleCloseAdvanceSearch: onhandleCloseAdvanceSearch,
	}));

	return (
		<div className="app-search-input">
			<div className="search-input">
				{children && 
					<div className="advance-search" onClick={onHandleShowAdvanceSearch}>
						<AppIcon src={searchIcon} />

						<div className="advance-search__input">
							{!isMultiValues && <p className="advance-search__placeholder">Searching for...</p>}
							<Pills />
						</div>
					</div>
				}

				{props.onChange && !props.multiValues && <AppInput type="text" name="search" placeholder="Searching for..." icon={searchIcon} defaultValue={props.defaultValue} onChange={onHandleChange} onClear={props.onClear} onBlur={() => {}} />}
			</div>

			{children && <Menu classes={{ root: "app-search-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseAdvanceSearch} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} transformOrigin={{ vertical: "top", horizontal: "left" }}>{children}</Menu>}

			{props.buttonLabel && !props.hideButton && (
				<div className="app-search-input__button-container">
					<AppButton type="button" label={props.buttonLabel} icon={props.buttonIcon} onClick={props.onButtonClick} />
				</div>
			)}
		</div>
	);
};

export default memo(forwardRef(AppSearchInput));

const buttonPropsType = (props, propsName, components) => {
	const propsType = {
		buttonIcon: PropTypes.string.isRequired,
		buttonLabel: PropTypes.string.isRequired,
		onButtonClick: PropTypes.func.isRequired,
	};

	if (props.buttonIcon || props.buttonLabel || props.onButtonClick) return PropTypes.checkPropTypes(propsType, props, propsName, components);

	return null;
};

const advanceSearchPropType = (props, propsName, components) => {
	const propsType = {
		multiValues: PropTypes.object.isRequired,
		onRemoveField: PropTypes.func.isRequired,
	};

	if (props.multiValues || props.onRemoveField) return PropTypes.checkPropTypes(propsType, props, propsName, components);

	return null;
};

AppSearchInput.propTypes = {
	multiValues: advanceSearchPropType,
	onRemoveField: advanceSearchPropType,
	onClear: PropTypes.func,
	onChange: PropTypes.func,
	buttonIcon: buttonPropsType,
	buttonLabel: buttonPropsType,
	onButtonClick: buttonPropsType,
};
