import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "common/class-names";
import AppButton from "components/app-button";
import { dismissVersionAlertMessage } from "store/slices/version-alert";

const AppModalAlert = (props) => {
	const alert = useSelector((state) => state.versionAlert);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [isHiding, setIsHiding] = useState(false);

	//prettier-ignore
	const appClassName = useMemo(() => classNames({
		"app-modal-alert": true,
		"app-modal-alert--active": visible,
		"app-modal-alert--inactive": isHiding,
	}), [visible, isHiding]);

	const onHandleShow = () => {
		setVisible(true);
	};

	const onHandleDismiss = () => {
		dispatch(dismissVersionAlertMessage());
		setVisible(false);
		setIsHiding(true);
		setTimeout(() => window.location.reload(), 1000);
	};

	const onHandleCancel = () => {
		dispatch(dismissVersionAlertMessage());
		setIsHiding(true);
		setVisible(false);
		setTimeout(() => setIsHiding(false), 1000);
	};

	useEffect(() => {
		if (alert.message) onHandleShow();
	}, [alert]);

	return (
		<div className={appClassName}>
			<div className="modal-alert">
				<div className="modal-alert__card">
					<p className="modal-alert__title">New Update Available</p>
					<p className="modal-alert__description">{alert.message}</p>

					<div className="modal-alert__button-container">
						<AppButton type="button" label="Do it later" outline onClick={onHandleCancel} />
						<AppButton type="button" label="Update now" onClick={onHandleDismiss} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppModalAlert;
