import React, { useMemo, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import ERRORS from "common/errors";
import api from "services/api";
import ROLES from "common/roles";
import pathnames from "routes/pathnames";
import { CALENDAR_FORMAT } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppMobileInput from "components/app-mobile-input";
import AppCalendarInput from "components/app-calendar-input";
import uploadIcon from "assets/images/upload-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";

const PageEnquiryMotorManual = (props) => {
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	let { id } = useParams();
	const uploadInputRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const updateAccessible = restrictedActions(ROLES.ENQUIRIES, ROLES.UPDATE_ENQUIRIES);
	/* prettier-ignore */
	const initialValues = useMemo(() => ({ enquiryNo: "", status: "", name: "", submissionDate: "", companyName: "", officeNo: "", officeNoPrefix: "", email: "", address1: "", address2: "", region: "", state: "", postcode: "", businessRegistrationNo:  "", natureOfBusiness: "", totalNoOfEmployee: "", highRiskEmployeeOccupation: "", remark: "", files: [], uploadedFiles: [] }), []);
	/* prettier-ignore */
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			files: yup.mixed().required(ERRORS.REQUIRED).test("fileSize", ERRORS.FILE_SIZE, (v) => validateFileSize(COMMON.MAX_FILE_SIZES.MB_15)),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const maxFilesLength = useMemo(() => formik.values.files.length === 5, [formik.values.files]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);

	const onHandleSubmit = async (values) => {
		let response = null;
		console.log(values)
		try {
			await api.post.motorManual.updateStatus({
				id: id,
				status: values.status,
				remarks: values.remark,
			})
			//await api.post.enquiries.update(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) onHandleUploadImage(values);
	};

	const onHandleUploadImage = async (values) => {
		const nextValues = { ...values };

		const onHandleSucess = () => {
			dispatch(promptAlertMessage({ message: "Enquiry has been updated successfull" }));
			navigate(-1);
		};

		if (!nextValues.files.length) return onHandleSucess();

		try {
			const formData = new FormData();

			formData.append("file", nextValues.files[0]);

			await api.post.enquiries.uploadDocument(id, formData);

			nextValues.files.shift();

			if (nextValues.files.length) {
				onHandleUploadImage({ ...nextValues });
			} else {
				onHandleSucess();
			}
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	const onHandleUploadFile = () => {
		uploadInputRef.current.click();
	};

	const onHandleChangeFile = (event) => {
		const file = event.target.files[0];
		const nextFiles = [...formik.values.files, file];
		formik.setFieldValue("files", nextFiles);

		uploadInputRef.current.value = "";
	};

	const onHandleRemoveFile = async (obj, index) => {
		let response = null;

		if (obj.filePath) {
			try {
				const payload = { enquiryId: id, filePath: obj.filePath };

				await api.post.enquiries.deleteDocument(payload);

				response = true;
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				let nextUploadedFiles = [...formik.values.uploadedFiles].filter((o) => o.filePath !== obj.filePath);
				formik.setFieldValue("uploadedFiles", nextUploadedFiles);
			}
		} else {
			let nextFiles = [...formik.values.files];
			nextFiles.splice(index, 1);
			formik.setFieldValue("files", nextFiles);
		}
	};

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				const payload = { id: id };
				response = await api.get.motorManual.enquiry.list(payload);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				var responseData = response.data;
				memoSetValues({
					enquiryNo: responseData.id,
					referenceNo: responseData.referenceNo,
					referralCode: responseData.referralCode,
					status: responseData.status,
					takaful: responseData.takaful,
					vehicleRegistrationNo: responseData.vehicleRegistrationNo,
					vehicleType: responseData.vehicleType,
					submissionDate: responseData.createdAt,
					name: responseData.fullName,
					identificationNo: responseData.identificationNo,
					identificationType: responseData.identificationType,
					maritalStatus: responseData.maritalStatus,
					companyName: responseData.companyName,
					companyReg: responseData.companyReg,
					officeNo: responseData.phoneNumber,
					email: responseData.email,
					postcode: responseData.postcode,
					remark: response.remarks || "",
					documentFolderPath: responseData.documentFolderPath,
					uploadedFiles: response.files || [],
					files: [],
				});
			}
		};

		if (id) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ENQUIRES.ENQUIRY);
		};
	}, [cancelRequest]);

	return (
		<div className="page-enquiry">
			<div className="enquiry">
				<h1 className="enquiry__name">{formik.values.enquiryNo}</h1>

				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							<div className="enquiry__wrapper">
								<div className="enquiry__box-body">
									{/* prettier-ignore */}
									<AppSelectInput required type="text" name="status" label="Status" placeholder="Please Select" options={COMMON.STATUS_DROPDOWNS.ENQUIRES} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} disabled={isDisabled} />
								</div>
							</div>
						</div>
					</div>

					<div className="enquiry__container">
						<div className="enquiry__box">
							<div className="enquiry__wrapper">
								<p className="enquiry__title">Enquiry</p>

								<div className="enquiry__box-body">
									{/* prettier-ignore */}
									<AppInput type="text" name="enquiryNo" label="Enquiry ID" placeholder="Enquiry ID" value={formik.values.enquiryNo} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppCalendarInput required name="submissionDate" label="Submission Date" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.submissionDate} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="name" label="Customer Name" placeholder="Customer Name" value={formik.values.name} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="companyName" label="Company Name" placeholder="Company Name" value={formik.values.companyName} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppMobileInput required type="number" name="officeNo" prefixNoName="officeNoPrefix" label="Office No." placeholder="Office No." value={formik.values.officeNo} prefixNo={formik.values.officeNoPrefix} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="email" label="Email Address" placeholder="Email Address" value={formik.values.email} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="email" label="Company Address (Line 1)" placeholder="Company Address (Line 1)" value={formik.values.address1} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="email" label="Company Address (Line 2)" placeholder="Company Address (Line 2)" value={formik.values.address2} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="state" label="State" placeholder="State" value={formik.values.state} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="region" label="Region" placeholder="Region" value={formik.values.region} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="postcode" label="Postcode" placeholder="Postcode" value={formik.values.postcode} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="businessRegistrationNo" label="Business Reg. No." placeholder="Business Reg. No." value={formik.values.businessRegistrationNo} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="natureOfBusiness" label="Nature of Business" placeholder="Nature of Business" value={formik.values.natureOfBusiness} disabled onChange={formik.handleChange} />

									<AppInput required type="text" name="email" label="No. of Employee" placeholder="No. of Employee" value={formik.values.totalNoOfEmployee} disabled onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="highRiskEmployeeOccupation" label="High Risk Occupation Employee" placeholder="High Risk Occupation Employee" value={formik.values.highRiskEmployeeOccupation ? "Yes" : "No"} disabled onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="enquiry__container">
						<div className="enquiry__box">
							<div className="enquiry__wrapper">
								<div className="enquiry__box-header">
									<p className="enquiry__title">Upload</p>
									<p className="enquiry__description">Please ensure that the document is in the correct format (jpg, pdf) with file size not exceeding 15MB.</p>
								</div>

								<div className="enquiry__wrapper enquiry__wrapper--upload">
									<div className="enquiry__box-body">
										<div className="upload-table">
											<div className="upload-table__header">
												<p className="upload-table__label">Uploaded</p>
												<p className="upload-table__label">File Size</p>
												<p className="upload-table__delete"></p>
											</div>

											{[...formik.values.files, ...formik.values.uploadedFiles].map((o, i) => {
												return (
													<div className="upload-table__body" key={i}>
														<div className="upload-table__values upload-table__values--uploaded">{o.name || o.fileName}</div>

														<div className="upload-table__values">{converReadableFileSize(o.size || o.fileSize, true)}</div>

														<div className="upload-table__delete">{!isDisabled && <img src={removeIcon} alt="delete" onClick={() => onHandleRemoveFile(o, i)} />}</div>
													</div>
												);
											})}

											{!maxFilesLength && (
												<div className="upload-table__body">
													<div className="upload-table__values">
														<AppButton type="button" label="Upload File" icon={uploadIcon} disabled={isDisabled} onClick={onHandleUploadFile} />
														<input type="file" name="file" accept="image/png, image/jpeg, application/pdf" hidden ref={uploadInputRef} onChange={onHandleChangeFile} />
													</div>

													<div className="upload-table__values">-</div>

													<div className="upload-table__delete"></div>
												</div>
											)}

											{formik.touched.files && formik.errors.files && <div className="upload-table__footer">{formik.errors.files}</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="enquiry__container">
						<div className="enquiry__box">
							<div className="enquiry__wrapper">
								{/*prettier-ignore*/}
								<AppInput multiline maxLength={5000} type="text" name="remark" label="Remarks" placeholder="Please enter remarks if any" value={formik.values.remark} disabled={isDisabled} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="enquiry__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={isDisabled} label="Save" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageEnquiryMotorManual;
