const ROLES = {
	ADMIN: "admin",
	VIEW_ADMIN: "admin:view",
	CREATE_ADMIN: "admin:create",
	UPDATE_ADMIN: "admin:update",
	DELETE_ADMIN: "admin:delete",

	AFFILIATE: "affiliate",
	VIEW_AFFILIATE: "affiliate:view",
	CREATE_AFFILIATE: "affiliate:create",
	UPDATE_AFFILIATE: "affiliate:update",

	USER: "user",
	VIEW_USER: "user:view",
	UPDATE_USER: "user:update",
	EXPORT_USER: "user:export",

	ROLE_PRIVILEGES: "role_and_privileges",
	VIEW_ROLE_PRIVILEGES: "role:view",
	CREATE_ROLE_PRIVILEGES: "role:create",
	UPDATE_ROLE_PRIVILEGES: "role:update",

	DROP_MESSAGE: "drop_message",
	VIEW_DROP_MESSAGE: "drop_message:view",
	UPDATE_DROP_MESSAGE: "drop_message:update",

	NEWS_ANNOUNCEMENTS: "news_announcements",
	VIEW_NEWS_ANNOUNCEMENTS: "news_announcements:view",
	CREATE_NEWS_ANNOUNCEMENTS: "news_announcements:create",
	UPDATE_NEWS_ANNOUNCEMENTS: "news_announcements:update",

	CAMPAIGNS: "banner",
	VIEW_CAMPAIGNS: "banner:view",
	CREATE_CAMPAIGNS: "banner:create",
	UPDATE_CAMPAIGNS: "banner:update",

	FAQS: "faq",
	VIEW_FAQS: "faq:view",
	CREATE_FAQS: "faq:create",
	UPDATE_FAQS: "faq:update",

	EB_PRODUCTS: "eb_order",

	ENQUIRIES: "enquiry",
	VIEW_ENQUIRIES: "enquiry:view",
	UPDATE_ENQUIRIES: "enquiry:update",

	CLAIMS: "eb_claim",
	VIEW_CLAIMS: "eb_claim:view",

	POLICIES: "policy",
	VIEW_POLICIES: "policy:view",
	UPDATE_POLICIES: "policy:update",

	ENDORSEMENT_AUDIT_LOG: "endorsement",
	VIEW_ENDORSEMENT_AUDIT_LOG: "endorsement:view",

	PAYMENT_AUDIT_LOG: "payment",
	VIEW_PAYMENT_AUDIT_LOG: "payment:view",

	AMENDMENT_REQUEST: "amendment_req",
	VIEW_AMENDMENT_REQUEST: "amendment_req:view",
	UPDATE_AMENDMENT_REQUEST: "amendment_req:update",

	AUDIT_REPORT: "audit_report",
	VIEW_AUDIT_REPORT: "audit_report:view",
};

export default ROLES;
