const queryParamsEntries = (searchParams) => {
	let params = {};

	for (const entry of searchParams.entries()) {
		const [key, value] = entry;
		params[key] = value;
	}

	return params;
};

export default queryParamsEntries;
