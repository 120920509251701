import axios from "axios";
import store from "store/store";
import sanitizeError from "./sanitize-error";
import { promptAlertMessage } from "store/slices/alert";

const isAxiosCancelled = "isAxiosCancelled";

export const axiosCancelled = (error) => error === isAxiosCancelled;

const serveRequestErrors = (error, callback) => {
	const dispatch = store.dispatch;
	const requestCancelled = axios.isCancel(error);

	if (requestCancelled) return isAxiosCancelled;

	const sanitizedError = sanitizeError(error);

	const errorCode = error?.response?.data?.errorCode || "";

	if (callback) {
		callback(sanitizedError);
	} else {
		dispatch(promptAlertMessage({ error: true, message: sanitizedError, code: errorCode }));
	}

	return sanitizedError;
};

export default serveRequestErrors;
