import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import "./styles/index.scss";
import packageJson from "../package.json";
import autoUpdateWindow from "common/auto-update-window";

const dom = document.getElementById("root");

dom?.setAttribute("data-version", packageJson.version);

const root = ReactDOM.createRoot(dom);

autoUpdateWindow();

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
