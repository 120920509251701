import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import getFaqCategories from "services/get-faq-catogories";
import { formatDateTime, isDateBeforeToday, CALENDAR_FORMAT, getISOString } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppSelectStatus from "components/app-select-status";
import AppCalendarInput from "components/app-calendar-input";

const PageFaq = (props) => {
	const defaultOptions = useRef();
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const updateAccessible = restrictedActions(ROLES.FAQS, isCreate ? ROLES.CREATE_FAQS : ROLES.UPDATE_FAQS);
	const initialEndDate = useMemo(() => getISOString("12/12/2099"), []);
	const initialValues = useMemo(() => ({ status: isCreate ? COMMON.STATUS_ID.ACTIVE : undefined, category: "", title: "", description: "", startDate: "", endDate: initialEndDate, lastModifiedDate: "", lastModifiedByName: "" }), [isCreate, initialEndDate]);
	//prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			category: yup.string().required(ERRORS.REQUIRED),
			title: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.string().required(ERRORS.REQUIRED).test("dateIsExpired", ERRORS.START_DATE_EXPIRED, function (value) { return isDateBeforeToday(new Date(), value) }).test("beforeEndDate", ERRORS.START_DATE, function (value) { return isDateBeforeToday(value, this.parent.endDate) }),
			endDate: yup.string().required(ERRORS.REQUIRED).test("afterStartDate", ERRORS.END_DATE, function (value) { return isDateBeforeToday(this.parent.startDate || new Date(), value) }),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const memoSetValues = useMemo(() => formik.setValues, [formik]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "New FAQ" : formik.values.title || "-"), [formik.values.title, isCreate]);

	const onHandleCancel = () => {
		navigate(-1);
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				status: values.status,
				title: values.title,
				category: values.category,
				description: values.description,
				startDate: values.startDate,
				endDate: values.endDate,
			};

			if (isCreate) {
				response = await api.post.faqs.create(payload);
			} else {
				payload.id = id;
				response = await api.post.faqs.update(payload);
			}
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: isCreate ? "Faq has been created." : "Faq has been updated." }));
			navigate(-1);
		}
	};

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.faqs.faq(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					title: response.title,
					endDate: response.endDate,
					category: response.category,
					description: response.description,
					startDate: response.startDate,
					lastModifiedByName: response.lastModifiedByName,
					lastModifiedDate: response.lastModifiedDate,
					status: response.status === COMMON.STATUS_ID.EXPIRED ? undefined : response.status,
				});

				defaultOptions.current = { label: response.category, value: response.category };
			}
		};

		if (isEdit) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.FAQS.FAQ);
		};
	}, [cancelRequest]);

	return (
		<div className="page-faq">
			<div className="faq">
				<h1 className="faq__name">{title}</h1>
				{!isCreate && (
					<p className="faq__date">
						Last updated by: {formik.values.lastModifiedByName}, {formatDateTime(new Date(formik.values.lastModifiedDate))}
					</p>
				)}

				<form className="faq__form" onSubmit={formik.handleSubmit}>
					<div className="faq__container">
						<div className="faq__box">
							<div className="faq__wrapper">
								<div className="faq__box-body">
									<AppSelectStatus value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} disabled={isDisabled} onChange={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppSelectInput pagination required type="text" name="category" label="Grouping" placeholder="Please Select" defaultOptions={defaultOptions.current} loadOptions={getFaqCategories} value={formik.values.category} error={formik.errors.category} touched={formik.touched.category} disabled={isDisabled} onChange={formik.handleChange} />

									{/*prettier-ignore*/}
									<AppInput required type="text" name="title" label="Title" placeholder="Please enter FAQ title" value={formik.values.title} disabled={isDisabled} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

									{/*prettier-ignore*/}
									<AppCalendarInput required name="startDate" label="Effective Date" placeholder="01 Jan 2023" minDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.startDate} disabled={isDisabled} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppCalendarInput required name="endDate" label="Expiry Date" placeholder="01/01/2099" minDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.endDate} disabled={isDisabled} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppInput required multiline maxLength={5000} type="text" name="description" label="Description" placeholder="Please enter a description of the news" value={formik.values.description} disabled={isDisabled} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="faq__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" label={submitLabel} disabled={isDisabled} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageFaq;
