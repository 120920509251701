import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, Fragment, useMemo, useEffect } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import api from "services/api";
import AppCheckbox from "components/app-checkbox";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import AppButton from "components/app-button";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppDownloadInvoiceModal = (props, ref) => {
	const [invoice, setInvoice] = useState({});
	const [visible, setVisible] = useState(false);
	const [documents, setDocuments] = useState();
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [selectedInvoice, setSelectedInvoice] = useState([]);
	const selectedAll = useMemo(() => {
		let total = 0;

		if (documents?.files) {
			Object.keys(documents.files).forEach((o) => {
				total += documents.files[o].length;
			});
		}

		return selectedInvoice?.length === total;
	}, [selectedInvoice, documents]);

	const onHandleGetDocuments = useCallback(async (obj) => {
		setInvoice(obj.invoiceNo);

		let response = null;

		try {
			response = await api.get.policies.invoiceFiles(obj.invoiceNo);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const sanitizedDocuments = {};

			response.forEach((o) => {
				if (!sanitizedDocuments.files) {
					sanitizedDocuments.files = {};
				}

				if (!sanitizedDocuments.files[obj.category]) {
					sanitizedDocuments.files[obj.category] = [];
				}

				sanitizedDocuments.policyNo = o.policyNo;
				sanitizedDocuments.policyFullName = o.policyFullName;
				sanitizedDocuments.files[obj.category].push(o);
			});

			setDocuments(sanitizedDocuments);
		}
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj, plan) => {
		setVisible(true);
		onHandleGetDocuments(obj, plan);
	}, [onHandleGetDocuments]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setSelectedInvoice([]);
		setDownloadProgress(0);
		setDocuments();
	}, []);

	//prettier-ignore
	const onHandleSelectInvoice = useCallback((event) => {
        const name = event.target.name;
        const isSelectedAllField = name === "all";

        if (isSelectedAllField) {
            let next = [];

            if(!selectedAll) next = Object.keys(documents.files)?.map((a) => documents.files[a].map(b => b.id.toString())).flat();
            
            setSelectedInvoice(next);
        } else {
            setSelectedInvoice((prev) => {
                const current = [...prev];
                const found = current.findIndex((o) => o === name) > -1;
                const next = current.filter((o) => o !== name);

                if (!found) next.push(name);

                return next;
            });
        }
    }, [documents, selectedAll]);

	const onHandleDownloadDocuments = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];
	
				if (fileName) return data;
	
				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			const payload = { invoiceNo: invoice, invoiceFileIds: selectedInvoice };
			response = await api.post.policies.downloadInvoices(payload, { onDownloadProgress, transformResponse });
		} catch (error) {
			setDownloadProgress(0);
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
			}, 1000);
		}
	}, [invoice, selectedInvoice]);

	useEffect(() => {
		const onHandleScrollHeight = () => {
			const element = document.querySelector(".download-invoice__list");

			if (element) element.style.maxHeight = `${window.innerHeight - 400}px`;
		};

		const debouncedCheck = debounce(onHandleScrollHeight, 50);

		if (documents?.files) onHandleScrollHeight();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [documents]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-download-invoice-modal" }} open={visible} aria-labelledby="download-invoice" aria-describedby="download-invoice-modal">
			<div className="download-invoice">
				<div className="download-invoice__header">
					<p className="download-invoice__title">Download Invoice</p>
					<p className="download-invoice__description">Please select invoice to download</p>
				</div>

				{!documents?.files && (
					<div className="download-invoice__loading">
						<LoadingLogo />
					</div>
				)}

				{documents?.files && (
					<Fragment>
						<ul className="download-invoice__list">
							<div className="download-invoice__list-header">
								All
								<AppCheckbox label="" onClick={onHandleSelectInvoice} name="all" value={selectedAll} />
							</div>

							{Object.keys(documents.files).map((a) => {
								const item = documents.files[a];
								return (
									<Fragment key={a}>
										<p className="download-invoice__category">{capitalizeCharacter(a)}</p>

										{item.map((b) => {
											const id = b.id.toString();
											const found = selectedInvoice?.findIndex((c) => c === id) > -1;

											return (
												<li className="download-invoice__item" key={b.id}>
													{b.fileName}
													<AppCheckbox label="" onClick={onHandleSelectInvoice} name={id} value={found} />
												</li>
											);
										})}
									</Fragment>
								);
							})}
						</ul>
					</Fragment>
				)}

				<div className="download-invoice__progress-bar" style={{ width: `${downloadProgress}%` }} />

				<div className="download-invoice__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Download" onClick={onHandleDownloadDocuments} disabled={!!downloadProgress || !selectedInvoice.length} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDownloadInvoiceModal));

AppDownloadInvoiceModal.propTypes = {
	ref: PropTypes.object,
};
