import React, { useMemo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import queryParamsEntries from "common/query-params-entries";
import AppTabs from "components/app-tabs";
import AppPoliciesMotorTable from "components/pages/insurance-management/app-policies-motor-table";
import AppPoliciesEmployeeBenefitTable from "components/pages/insurance-management/app-policies-employee-benefit-table";
import AppPoliciesTravelTable from "components/pages/insurance-management/app-policies-travel-policy-table";

const PagePolicies = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();

	//prettier-ignore
	const tabsNav = useMemo(() => {
		let list = [
			{ label: "Employee Benefit", value: "EMPLOYEE_BENEFIT" },
			{ label: "Motor", value: "MOTOR" },
			{ label: "Motor Manual", value: "MOTOR_MANUAL" },
			{ label: "PA", value: "PA" },
			{ label: "Travel", value: "TRAVEL" },
		];

		return list;
	}, []);

	const defaultTab = useMemo(() => {
		const paramTab = searchParams.get("tab");

		if (paramTab) {
			const found = tabsNav.findIndex((o) => o.value === paramTab) > -1;

			if (found) return paramTab;
			else return tabsNav[0].value;
		}

		return tabsNav[0].value;
	}, [searchParams, tabsNav]);
	const [tab, setTab] = useState(defaultTab);

	//prettier-ignore
	const onHandleChangeTab = useCallback((value) => {
		const params = queryParamsEntries(searchParams);

		setSearchParams({ ...params, tab: value });

		setTab(value);
	}, [searchParams, setSearchParams]);

	const PoliciesTable = useCallback((obj) => {
		switch (obj.tab) {
			case "EMPLOYEE_BENEFIT":
				return <AppPoliciesEmployeeBenefitTable {...obj} />;
			case "MOTOR":
				return <AppPoliciesMotorTable {...obj} />;
			case "MOTOR_MANUAL":
				return <AppPoliciesMotorTable {...obj} />;
			case "PA":
				return <AppPoliciesMotorTable {...obj} />;
			case "TRAVEL":
				return <AppPoliciesTravelTable {...obj} />;
			default:
				return null;
		}
	}, []);

	return (
		<div className="page-policies">
			<AppTabs tabs={tabsNav} value={tab} onChange={onHandleChangeTab} />

			<PoliciesTable tab={tab} onHandleCancelRequest={props.onHandleCancelRequest} />
		</div>
	);
};

export default PagePolicies;
