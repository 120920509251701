import COMMON from "common";
import services, {services_2} from "services";
import encodeQueryParams from "common/encode-query-params";

const ignoreAuthHeader = { token: false };
const multipart = { headers: { "Content-Type": "multipart/form-data" } };

const api = {
	get: {
		general: {
			profile: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.PROFILE, { cancelId: COMMON.ENDPOINT_PATH.GENERAL.PROFILE }),
			occupation: (item, params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.OCCUPATION + item + encodeQueryParams(params)),
			occupationList: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.OCCUPATION_LIST + encodeQueryParams(params)),
			faqCategories: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.FAQ_CATEGORIES + encodeQueryParams(params)),
			maintenance: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE, { baseURL: process.env.REACT_APP_URL, cancelId: COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE }),
		},
		rolesPrivileges: {
			permissionList: () => services.get(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.PERMISSION_LIST, { cancelId: COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.PERMISSION_LIST }),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.LIST }),
			role: (params) => services.get(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.ROLE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.ROLE }),
		},
		admin: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ADMIN.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ADMIN.LIST }),
			admin: (params) => services.get(COMMON.ENDPOINT_PATH.ADMIN.ADMIN + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ADMIN.ADMIN }),
		},
		customer: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.CUSTOMER.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CUSTOMER.LIST }),
			customer: (params) => services.get(COMMON.ENDPOINT_PATH.CUSTOMER.CUSTOMER + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CUSTOMER.CUSTOMER }),
		},
		campaign: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.CAMPAIGN.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CAMPAIGN.LIST }),
			campaign: (params) => services.get(COMMON.ENDPOINT_PATH.CAMPAIGN.CAMPAIGN + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CAMPAIGN.CAMPAIGN }),
		},
		newsAnnouncement: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.LIST }),
			announcement: (params) => services.get(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.NEWS_ANNOUNCEMENT + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.NEWS_ANNOUNCEMENT }),
		},
		amendmentRequest: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST }),
			amendmentRequest: (params) => services.get(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.AMENDMENT_REQUEST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.AMENDMENT_REQUEST }),
		},
		productEb: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.PRODUCT_EB.ORDERS + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.PRODUCT_EB.ORDERS }),
			order: (params) => services.get(COMMON.ENDPOINT_PATH.PRODUCT_EB.ORDER + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.PRODUCT_EB.ORDER }),
			paymentStatus: (params) => services.get(COMMON.ENDPOINT_PATH.PRODUCT_EB.PAYMENT_STATUS + encodeQueryParams(params)),
			export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.PRODUCT_EB.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
		},
		enquiries: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.ENQUIRES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ENQUIRES.LIST }),
			enquiry: (params) => services.get(COMMON.ENDPOINT_PATH.ENQUIRES.ENQUIRY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.ENQUIRES.ENQUIRY }),
			export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.ENQUIRES.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
		},
		claims: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.LIST }),
			claim: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.CLAIM + encodeQueryParams(params)),
			export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
		},
		policies: {
			ebList: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.EB_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.POLICIES.EB_LIST }),
			policy: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.POLICY + encodeQueryParams(params)),
			insurer: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.INSURER + encodeQueryParams(params)),
			documents: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DOCUMENTS + encodeQueryParams(params)),
			invoiceFiles: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.INVOICE_FILES + encodeQueryParams(params)),
			creditNote: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_NOTE + encodeQueryParams(params)),
			debitNote: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_NOTE + encodeQueryParams(params)),
			creditPaymentHistory: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_PAYMENT_HISTORY + encodeQueryParams(params)),
			creditPaymentReceipt: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_PAYMENT_HISTORY_RECEIPT + encodeQueryParams(params)),
			debitPaymentHistory: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_PAYMENT_HISTORY + encodeQueryParams(params)),
			debitPaymentReceipt: (params) => services.get(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_PAYMENT_HISTORY_RECEIPT + encodeQueryParams(params)),
			export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.POLICIES.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
		},
		motor: {
			enquiry: {
				list: (params) => services.get(COMMON.ENDPOINT_PATH.MOTOR.ENQUIRY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.MOTOR.ENQUIRY.LIST }),
				motor: (params) => services.get(COMMON.ENDPOINT_PATH.MOTOR.ENQUIRY.MOTOR + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.MOTOR.ENQUIRY.MOTOR }),
				export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.MOTOR.ENQUIRY.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			},
			policy: {
				list: (params) => services.get(COMMON.ENDPOINT_PATH.MOTOR.POLICY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.MOTOR.POLICY.LIST }),
				motor: (params) => services.get(COMMON.ENDPOINT_PATH.MOTOR.POLICY.MOTOR + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.MOTOR.POLICY.MOTOR }),
				export: (params, headers) => services.get(COMMON.ENDPOINT_PATH.MOTOR.POLICY.EXPORT + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			},
		},
		motorManual: {
			enquiry: {
				list: (params) => services_2.get(COMMON.ENDPOINT_PATH.MOTOR_MANUAL.ENQUIRY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.MOTOR_MANUAL.ENQUIRY.LIST }),
			}
		},
		travel: {
			enquiry:{
				list: (params) => services_2.get(COMMON.ENDPOINT_PATH.TRAVEL.ENQUIRY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.TRAVEL.ENQUIRY.LIST }),
			},
			order:{
				list: (params) => services_2.get(COMMON.ENDPOINT_PATH.TRAVEL.ORDER.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.TRAVEL.ENQUIRY.LIST }),
			},
			policy: {
				list: (params) => services_2.get(COMMON.ENDPOINT_PATH.TRAVEL.POLICY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.TRAVEL.POLICY.LIST }),
			}
		},
		personalAccident: {
			enquiry: {
				list: (params) => services_2.get(COMMON.ENDPOINT_PATH.PERSONALACCIDENT.ENQUIRY.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.PERSONALACCIDENT.ENQUIRY.LIST }),
			}
		},
		renewal: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.RENEWAL.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.RENEWAL.LIST }),
			renewal: (params) => services.get(COMMON.ENDPOINT_PATH.RENEWAL.RENEWAL + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.RENEWAL.RENEWAL }),
		},
		faqs: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.FAQS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.FAQS.LIST }),
			faq: (params) => services.get(COMMON.ENDPOINT_PATH.FAQS.FAQ + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.FAQS.FAQ }),
		},
		contactUs: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.CONTACT_US.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CONTACT_US.LIST }),
			message: (params) => services.get(COMMON.ENDPOINT_PATH.CONTACT_US.MESSAGE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CONTACT_US.MESSAGE }),
		},
		auditLog: {
			endorsementDebitNote: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_DEBIT_NOTE + encodeQueryParams(params)),
			endorsementDebitNoteList: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_DEBIT_NOTE_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_DEBIT_NOTE_LIST }),
			endorsementCreditNote: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_CREDIT_NOTE + encodeQueryParams(params)),
			endorsementCreditNoteList: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_CREDIT_NOTE_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AUDIT_LOG.ENDORSEMENT_CREDIT_NOTE_LIST }),
			exportEndorsementDebitNote: (params, headers) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.EXPORT_ENDORSEMENT_DEBIT_NOTE + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			exportEndorsementCreditNote: (params, headers) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.EXPORT_ENDORSEMENT_CREDIT_NOTE + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			paymentApplicationList: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.PAYMENT_APPLICATION_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AUDIT_LOG.PAYMENT_APPLICATION_LIST }),
			paymentInvoiceList: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.PAYMENT_INVOICE_LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AUDIT_LOG.PAYMENT_INVOICE_LIST }),
			exportPaymentApplication: (params, headers) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.EXPORT_PAYMENT_APPLICATION + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			exportPaymentInvoice: (params, headers) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.EXPORT_PAYMENT_INVOICE + encodeQueryParams(params), { ...headers, responseType: "arraybuffer" }),
			invoicePaymentStatus: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.INVOICE_PAYMENT_STATUS + encodeQueryParams(params)),
			applicationPaymentStatus: (params) => services.get(COMMON.ENDPOINT_PATH.AUDIT_LOG.APPLICATION_PAYMENT_STATUS + encodeQueryParams(params)),
		},
		reporting: {
			reportTypes: () => services.get(COMMON.ENDPOINT_PATH.REPORTING.REPORT_TYPES, { cancelId: COMMON.ENDPOINT_PATH.REPORTING.REPORT_TYPES }),
			generate: (params, headers) => services.get(COMMON.ENDPOINT_PATH.REPORTING.GENERATE + encodeQueryParams(params), { responseType: "arraybuffer", ...headers }),
		},
		affialiateProfile: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.LIST }),
			affialiateProfile: (params) => services.get(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.AFFILIATE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.AFFILIATE }),
		},
	},
	post: {
		general: {
			refreshToken: (payload) => services.post(COMMON.ENDPOINT_PATH.GENERAL.REFRESH_TOKEN, payload, { ...ignoreAuthHeader }),
		},
		authenticate: {
			signIn: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.SIGN_IN, payload),
			resetPassword: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.RESET_PASSWORD, payload),
			forgotPassword: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.FORGOT_PASSWORD, payload),
			updatePassword: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.UPDATE_PASSWORD, payload),
			verifyForgotPassword: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.VERIFY_FORGOT_PASSWORD, payload, { cancelId: COMMON.ENDPOINT_PATH.AUTHENTICATE.VERIFY_FORGOT_PASSWORD }),
		},
		rolesPrivileges: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.UPDATE, payload),
		},
		admin: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.UPDATE, payload),
			delete: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.DELETE, payload),
			updateStatus: (payload) => services.post(COMMON.ENDPOINT_PATH.ADMIN.UPDATE_STATUS, payload),
		},
		customer: {
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.CUSTOMER.UPDATE, payload),
			updateStatus: (payload) => services.post(COMMON.ENDPOINT_PATH.CUSTOMER.UPDATE_STATUS, payload),
		},
		campaign: {
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.CAMPAIGN.UPDATE, payload),
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.CAMPAIGN.CREATE, payload),
			uploadImage: (params, payload) => services.post(COMMON.ENDPOINT_PATH.CAMPAIGN.UPLOAD_IMAGE + encodeQueryParams(params), payload, { ...multipart }),
		},
		newsAnnouncement: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.UPDATE, payload),
			deleteImage: (params) => services.post(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.DELETE_IMAGE + encodeQueryParams(params)),
			uploadImage: (params, payload) => services.post(COMMON.ENDPOINT_PATH.NEWS_ANNOUNCEMENT.UPLOAD_IMAGE + encodeQueryParams(params), payload, { ...multipart }),
		},
		amendmentRequest: {
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.UPDATE, payload),
		},
		enquiries: {
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.ENQUIRES.UPDATE, payload),
			deleteDocument: (payload) => services.post(COMMON.ENDPOINT_PATH.ENQUIRES.DELETE_DOCUMENT, payload),
			uploadDocument: (params, payload) => services.post(COMMON.ENDPOINT_PATH.ENQUIRES.UPLOAD_DOCUMENT + encodeQueryParams(params), payload, { ...multipart }),
		},
		faqs: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.FAQS.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.FAQS.UPDATE, payload),
		},
		policies: {
			downloadInvoices: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DOWNLOAD_INVOICES, payload, { responseType: "arraybuffer", ...headers }),
			downloadDocuments: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DOWNLOAD_DOCUMENTS, payload, { responseType: "arraybuffer", ...headers }),
			downloadDebitReceipt: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.DEBIT_DOWNLOAD_RECEIPT, payload, { responseType: "arraybuffer", ...headers }),
			downloadCreditReceipt: (payload, headers) => services.post(COMMON.ENDPOINT_PATH.POLICIES.CREDIT_DOWNLOAD_RECEIPT, payload, { responseType: "arraybuffer", ...headers }),
		},
		contactUs: {
			sendMessage: (payload) => services.post(COMMON.ENDPOINT_PATH.CONTACT_US.SEND_MESSAGE, payload),
			endConversation: (params) => services.post(COMMON.ENDPOINT_PATH.CONTACT_US.END_CONVERSATION + encodeQueryParams(params)),
		},
		affialiateProfile: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.CREATE, payload),
			update: (payload) => services.post(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.UPDATE, payload),
		},
		motorManual: {
			updateStatus: (payload) => services_2.post(COMMON.ENDPOINT_PATH.MOTOR_MANUAL.ENQUIRY.UPDATESTATUS, payload),
		},
		personalAccident: {
			updateStatus: (payload) => services_2.post(COMMON.ENDPOINT_PATH.PERSONALACCIDENT.ENQUIRY.UPDATESTATUS, payload),
		},
		travel: {
			updateStatus: (payload) => services_2.post(COMMON.ENDPOINT_PATH.TRAVEL.ORDER.UPDATESTATUS, payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
