import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import { debounce } from "lodash";
import pathnames from "routes/pathnames";
import eclipseString from "common/eclipse-string";
import sanitizeObject from "common/sanitize-object";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import { formatDatePattern, formatDateTime } from "common/calendar";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import addIcon from "assets/images/add-icon.svg";

const PageFaqs = (props) => {
	const navigate = useNavigate();
	const tableRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({
		title: searchParams.get("title") || "",
		status: searchParams.get("status") || "",
		category: searchParams.get("category") || "",
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.title && !paramsRef.current.status && !paramsRef.current.category, [data.status]);
	const createAccessible = restrictedActions(ROLES.FAQS, ROLES.CREATE_FAQS);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.faqs.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleCreate = () => {
		navigate(pathnames.customerSupport.faq + COMMON.APP_STATUS.NEW);
	};

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		navigate(pathnames.customerSupport.faq + id);
	}, [navigate]);

	const onHandleSearch = (event) => {
		const value = event.target.value;
		paramsRef.current.title = value;
		paramsRef.current.status = "";
		paramsRef.current.sort = "";
		paramsRef.current.page = 1;

		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleClearSearch = (event) => {
		paramsRef.current.title = "";
		paramsRef.current.status = "";
		paramsRef.current.sort = defaultSortBy;
		paramsRef.current.page = 1;

		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(row.original.id)} />; 
	}, [onHandleEdit]);

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Grouping",
			accessor: "category",
			disableSortBy: false,
		},
		{
			Header: "Title",
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: "Description",
			accessor: "description",
			disableSortBy: true,
            Cell: ({ row }) => <AppTableCell left value={eclipseString(row.original.description)} />,
		},
		{
			Header: "Effective Date",
			accessor: "startDate",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(row.original.startDate)} />,
		},
		{
			Header: "Expiry Date",
			accessor: "endDate",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(row.original.endDate)} />,
		},
		{
			Header: "Date Added",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(row.original.createdDate)} />,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: false,
			Cell: ({ row }) => <AppStatus status={row.original.status} />,
		},
		{
			Header: "Last Update by",
			accessor: "lastModifiedDate",
			disableSortBy: false,
			Cell: ({ row }) => <AppTableCell left value={`${row.original.lastModifiedByName}\n${formatDateTime(new Date(row.original.lastModifiedDate))}`} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.FAQS.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-faqs">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={!createAccessible} onClick={onHandleCreate} />}

			{!isEmptyState && (
				<div className="faqs">
					<AppSearchInput hideButton={!createAccessible} buttonLabel="Add New" buttonIcon={addIcon} onButtonClick={onHandleCreate} defaultValue={paramsRef.current.title} onChange={onHandleDebounceSearch} onClear={onHandleClearSearch} />

					{paramsRef.current.title && (
						<div className="faqs__results">
							<p className="faqs__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}
		</div>
	);
};

export default PageFaqs;
