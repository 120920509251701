import React, { useRef, useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { debounce } from "lodash";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import pathnames from "routes/pathnames";
import sanitizeObject from "common/sanitize-object";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import AppStatus from "components/app-status";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableMoreIcon } from "components/app-table";
import AppRemoveUserModal from "components/pages/user-management/app-remove-user-modal";
import addIcon from "assets/images/add-icon.svg";
import editIcon from "assets/images/edit-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";
import activeIcon from "assets/images/activate-icon.svg";
import deactivateIcon from "assets/images/deactivate-icon.svg";

const PageUsers = (props) => {
	const navigate = useNavigate();
	const tableRef = useRef();
	const removeUserModalRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedUser, setSelectedUser] = useState({});
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, status: searchParams.get("status") || "", email: searchParams.get("email") || "", sort: searchParams.get("sort") || "" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isActiveUser = useMemo(() => selectedUser?.status === COMMON.STATUS_ID.ACTIVE, [selectedUser]);
	const createAccessible = restrictedActions(ROLES.ADMIN, ROLES.CREATE_ADMIN);
	const deleteRestricted = restrictedActions(ROLES.ADMIN, ROLES.DELETE_ADMIN);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.admin.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
			}));
		}
	}, []);

	const onHandleUpdateStatus = useCallback(async () => {
		setAnchorEl(null);

		let response = null;

		try {
			const payload = {
				id: selectedUser.id,
				status: isActiveUser ? COMMON.STATUS_ID.INACTIVE : COMMON.STATUS_ID.ACTIVE,
			};
			response = await api.post.admin.updateStatus(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			onHandleGetList();
		}
	}, [selectedUser, isActiveUser, onHandleGetList]);

	const onHandleSort = (id, order) => {
		switch (id) {
			case "role":
				paramsRef.current.sort = order ? id + ".roleNameEn," + order : "";
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : "";
				break;
		}

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleTableMenu = (event, { row }) => {
		setSelectedUser(row.original);
		setAnchorEl(event.currentTarget);
	};

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	const onHandleCreate = () => {
		navigate(pathnames.userManagement.user + COMMON.APP_STATUS.NEW);
	};

	const onHandleEdit = () => {
		setAnchorEl(null);
		navigate(pathnames.userManagement.user + selectedUser.id);
	};

	const onHandleRemove = () => {
		setAnchorEl(null);
		removeUserModalRef.current.onHandleShow(selectedUser);
	};

	const onHandleSearch = (event) => {
		const value = event.target.value.trim();
		paramsRef.current.email = value;
		paramsRef.current.status = "";
		paramsRef.current.sort = "";
		paramsRef.current.page = 1;

		setSearchParams({ email: value });
		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleClearSearch = (event) => {
		paramsRef.current.email = "";
		paramsRef.current.status = "";
		paramsRef.current.sort = "";
		paramsRef.current.page = 1;

		setSearchParams("");
		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const MenuCell = useCallback((props) => {
		return <AppTableMoreIcon onClick={(event) => onHandleTableMenu(event, props)} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "User Name",
			accessor: "fullName",
			disableSortBy: false,
		},
		{
			Header: "User ID",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Role",
			accessor: "role",
			disableSortBy: false,
			Cell: (props) => props?.row?.values?.role?.roleNameEn || "-",
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: false,
			manualSortBy: true,
			Cell: (props) => <AppStatus status={props.row.values.status} />,
		},
		{	
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ADMIN.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-users">
			<div className="users">
				<AppSearchInput hideButton={!createAccessible} buttonLabel="Add New" buttonIcon={addIcon} onButtonClick={onHandleCreate} defaultValue={paramsRef.current.email} onChange={onHandleDebounceSearch} onClear={onHandleClearSearch} />

				{paramsRef.current.email && (
					<div className="users__results">
						<p className="users__text">{data.totalElements} results found</p>
					</div>
				)}

				<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />

				{/* prettier-ignore */}
				<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<MenuItem onClick={onHandleEdit}><img className="app-table-menu__icon" src={editIcon} alt="view" />Edit</MenuItem>
					<MenuItem onClick={onHandleUpdateStatus}><img className="app-table-menu__icon" src={isActiveUser ? deactivateIcon : activeIcon} alt="status" />{isActiveUser ? "Inactive" : "Active"}</MenuItem>
					{deleteRestricted && (<MenuItem className="app-table-menu__remove" onClick={onHandleRemove}><img className="app-table-menu__icon" src={removeIcon} alt="remove" />Remove</MenuItem>)}
				</Menu>
			</div>

			<AppRemoveUserModal ref={removeUserModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageUsers;
