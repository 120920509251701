import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useMemo, Fragment } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import converReadableFileSize from "common/convert-readable-file-size";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";

const TABS = {
	SUMMARY: "SUMMARY",
	DOCUMENTS: "DOCUMENTS",
};

export const AppRenewalModal = (props, ref) => {
	const [data, setData] = useState();
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS.SUMMARY);
	const quotation = useMemo(() => data?.quotations?.find((o) => o.status === COMMON.STATUS_ID.ACCEPTED), [data]);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"renewal-modal__tab": true,
			"renewal-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const renewalInfo = useMemo(() => {
		const info = [
			{ label: "Company Name", value: data?.companyName || "-" },
			{ label: "Business Reg. No.", value: data?.businessRegNo || "-" },
			{ label: "Current Policy Expiry", value: formatDatePattern(data?.policies?.[0]?.oldPolicyExpiryDate) },
			{ label: "Renewal Policy Expiry", value: formatDatePattern(data?.policies?.[0]?.newPolicyExpiryDate) },
			{ label: "Current Premium", value: `RM ${formatCurrency(data?.oldNettPremium)}` },
			{ label: "Renewal Premium", value: quotation?.renewalPremium ? `RM ${formatCurrency(quotation.renewalPremium)}` : "-" },
		];
		return info;
	}, [data, quotation]);

	const onHandleShow = useCallback(async (id) => {
		setVisible(true);

		let response = null;

		try {
			response = await api.get.renewal.renewal(id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-renewal-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="renewal-modal" aria-describedby="product-profile-modal">
			<div className="renewal-modal">
				<div className="renewal-modal__main">
					<button className="renewal-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="renewal-modal__header">
						<div className="renewal-modal__profile">
							<p className="renewal-modal__title">{data?.refNo || ""}</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="renewal-modal__text">Renewal Premium Amount</p>
							<p className="renewal-modal__title">{quotation?.renewalPremium ? `RM ${formatCurrency(quotation.renewalPremium)}` : "-"}</p>
						</div>
						<div className="info__box">
							<p className="renewal-modal__text">Status</p>
							<div className="renewal-modal__title">
								<AppStatus status={data?.status || ""} />
							</div>
						</div>
					</div>

					{data?.rejectReason && data?.rejectReasonCategory && (
						<div className="remarks">
							<div className="remarks__body">
								<div className="remarks__header">
									<p className="remarks__title">Remarks</p>
								</div>

								<div className="remarks__content">
									<p className="remarks__text">{[data?.rejectReasonCategory, data?.rejectReason].filter((o) => o).join(" - ")}</p>
								</div>
							</div>
						</div>
					)}

					<div className="renewal-modal__tabs">
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.SUMMARY)} onClick={() => onHandleTabChange(TABS.SUMMARY)}>Summary</button>
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.DOCUMENTS)} onClick={() => onHandleTabChange(TABS.DOCUMENTS)}>Attached Documents</button>
					</div>

					{TABS.SUMMARY === tab && (
						<div className="renewal-modal__body">
							<div className="renewal-modal__container">
								<div className="renewal-modal__wrapper">
									{renewalInfo.map((o, i) => {
										return (
											<div className="renewal-modal__info" key={i}>
												<p className="renewal-modal__label">{o.label}</p>
												<p className="renewal-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>

							<div className="renewal-modal__container">
								<div className="renewal-modal__product-wrapper">
									{data?.policies?.map((o, i) => {
										return (
											<div className="renewal-modal__product" key={i}>
												<p className="renewal-modal__product-title">{o.name}</p>

												<div className="renewal-modal__info">
													<p className="renewal-modal__label">Current Policy No.</p>
													<p className="renewal-modal__value">{o.oldPolicyNo}</p>
												</div>

												<div className="renewal-modal__info">
													<p className="renewal-modal__label">Renewal Policy No.</p>
													<p className="renewal-modal__value">{o.newPolicyNo || "-"}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}

					{TABS.DOCUMENTS === tab && (
						<div className="renewal-modal__body">
							<div className="renewal-modal__container">
								{data?.quotations?.map((o, i) => {
									const firstItem = i === 0;

									return (
										<Fragment key={i}>
											<div className="renewal-modal__docs-header">
												<p className="renewal-modal__label">Quotation</p>
												<p className="renewal-modal__label">{firstItem ? "Uploaded" : ""}</p>
												<p className="renewal-modal__label">{firstItem ? "File Size" : ""}</p>
											</div>

											<ul className="renewal-modal__documents">
												{o.quotationFiles?.map((a, j) => {
													return (
														<li className="renewal-modal__item" key={j}>
															<p className="renewal-modal__label">{a.fileName}</p>
															<p className="renewal-modal__label">
																<a className="renewal-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
																	{a.fileName}
																</a>
															</p>
															<p className="renewal-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
														</li>
													);
												})}
											</ul>
										</Fragment>
									);
								})}

								{Boolean(data?.policies?.length) &&
									data.policies.map((o, i) => {
										return (
											<Fragment key={i}>
												<div className="renewal-modal__docs-header">
													<p className="renewal-modal__label">{o.name}</p>
													<p className="renewal-modal__label"></p>
													<p className="renewal-modal__label"></p>
												</div>

												<ul className="renewal-modal__documents">
													{o.policyFiles?.map((a, j) => {
														return (
															<li className="renewal-modal__item" key={j}>
																<p className="renewal-modal__label">{a.fileName}</p>
																<p className="renewal-modal__label">
																	<a className="renewal-modal__link" href={a.filePath} target="_blank" rel="noreferrer">
																		{a.fileName}
																	</a>
																</p>
																<p className="renewal-modal__label">{converReadableFileSize(a.fileSize, true)}</p>
															</li>
														);
													})}
												</ul>
											</Fragment>
										);
									})}
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRenewalModal));

AppRenewalModal.propTypes = {
	ref: PropTypes.object,
};
