import React, { memo } from "react";

const AppCheckIcon = ({ color = "white", className }) => {
	return (
		<svg className={className} width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.27002 4.94L5.94002 9.65L14.74 0.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppCheckIcon);
