import React, { useMemo, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { formatDateTime } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppMobileInput from "components/app-mobile-input";
import AppSelectStatus from "components/app-select-status";
import copyIcon from "assets/images/copy-icon.svg";

const PageAffiliateProfile = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const updateAccessible = restrictedActions(ROLES.AFFILIATE, ROLES.UPDATE_AFFILIATE);
	//prettier-ignore
	const initialValues = useMemo(() => ({ status: isCreate ? COMMON.STATUS_ID.ACTIVE : undefined, referralCode: "", description: "", name: "", mobileNo: "", prefixNo: "", email: "", hyperlinks: [], lastModifiedDate: "", lastModifiedByName: "" }), [isCreate]);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			referralCode: yup.string().required(ERRORS.REQUIRED),
			name: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);

	const onHandleClipboard = (url) => {
		navigator.clipboard.writeText(url);

		dispatch(promptAlertMessage({ message: `${url} copied.` }));
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				email: values.email,
				mobileNo: values.mobileNo,
				mobileNoPrefix: values.prefixNo,
				description: values.description,
				referralCode: values.referralCode,
				status: values.status,
			};

			if (isCreate) {
				await api.post.affialiateProfile.create(payload);
			} else {
				payload.id = id;
				await api.post.affialiateProfile.update(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: `Affiliate profile has been updated successfull` }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.affialiateProfile.affialiateProfile(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					status: response.status,
					name: response.name,
					email: response.email,
					mobileNo: response.mobileNo,
					description: response.description,
					prefixNo: response.mobileNoPrefix,
					hyperlinks: response.hyperlinks,
					referralCode: response.referralCode,
					lastModifiedDate: response.lastModifiedDate,
					lastModifiedByName: response.lastModifiedByName,
				});
			}
		};

		if (isEdit) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.AFFILIATE);
		};
	}, [cancelRequest]);

	return (
		<div className="page-affiliate-profile">
			<div className="affiliate-profile">
				{isCreate && <h1 className="affiliate-profile__name">New Affiliate Referral Code</h1>}

				{!isCreate && (
					<Fragment>
						<h1 className="affiliate-profile__name">{formik.values.name}</h1>
						<p className="affiliate-profile__date">
							Last updated by: {formik.values.lastModifiedByName}, {formatDateTime(new Date(formik.values.lastModifiedDate))}
						</p>
					</Fragment>
				)}

				<form className="affiliate-profile__form" onSubmit={formik.handleSubmit}>
					<div className="affiliate-profile__container">
						<div className="affiliate-profile__box">
							<div className="affiliate-profile__wrapper">
								<div className="affiliate-profile__box-body">
									<AppSelectStatus value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} disabled={isDisabled} onChange={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppInput required copied={!isCreate} type="text" name="referralCode" label="Affiliate Code" placeholder="Affiliate Code" value={formik.values.referralCode} error={formik.errors.referralCode} touched={formik.touched.referralCode} disabled={!isCreate} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="name" label="Affiliate Name" placeholder="Enter Affiliate Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled}onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="email" label="Email Address" placeholder="Enter Enter Email Address" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} disabled={isDisabled} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppMobileInput required type="number" name="mobileNo" label="Mobile No." placeholder="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} disabled={isDisabled} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppInput required multiline maxLength={5000} type="text" name="description" label="Description" placeholder="Please enter a description of the news" value={formik.values.description} disabled={isDisabled} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>
					<div className="affiliate-profile__container">
						<div className="affiliate-profile__box">
							<div className="affiliate-profile__wrapper">
								<div className="affiliate-profile__box-header">
									<p className="affiliate-profile__title">Affiliate Hyperlink</p>
								</div>

								<div className="affiliate-profile__box-body">
									<ul className="affiliate-profile__list">
										{formik.values?.hyperlinks?.map((o, i) => {
											//prettier-ignore
											return (
												<li className="affiliate-profile__item" key={i}>
													<p className="affiliate-profile__product">{o.name}</p>
													<p className="affiliate-profile__url">{" • "}{o.url}
													<img className="affiliate-profile__copy" src={copyIcon} alt="copy" onClick={() => onHandleClipboard(o.url)} /></p>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="affiliate-profile__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={isDisabled} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageAffiliateProfile;
