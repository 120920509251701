import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import dummy from "assets/images/temp/dummy.jpeg";
import AppArrowIcon from "components/icons/app-arrow-icon";
import notificationIcon from "assets/images/components/app-header/notification-icon.svg";

const AppHeader = ({ title = "Dashboard", ...props }) => {
	const profile = useSelector((state) => state.profile);
	const navigate = useNavigate();

	return (
		<header className="app-header">
			<div className="header">
				<button id="header-menu" className="header__menu" onClick={props.onHandleToggleSideNav} />
				{title && <h1 className="header__title">{title}</h1>}
				{!title && (
					<button className="header__back" onClick={() => navigate(-1)}>
						<div className="header__back-icon">
							<AppArrowIcon color="#018897" />
						</div>
						Back to lists
					</button>
				)}

				<div className="header__profile">
					<div className="header__content">
						<p className="header__name">{profile.fullName}</p>
						<p className="header__text">{profile?.role?.roleNameEn}</p>
					</div>

					<div className="header__image">
						<img src={dummy} alt="profile" />
					</div>

					<div className="header__notification">
						<img src={notificationIcon} alt="notifications" />
					</div>
				</div>
			</div>
		</header>
	);
};

export default memo(AppHeader);
