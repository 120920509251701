import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import getOccupationItem from "services/get-occupation-item";
import serveRequestErrors from "common/serve-request-errors";
import { formatDate, formatDateTime, getISOString } from "common/calendar";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import profile from "assets/images/temp/temp.webp";
import AppCloseIcon from "components/icons/app-close-icon";
import editIcon from "assets/images/components/pages/user-management/edit-icon.svg";

const TABS = {
	PROFILE: "PROFILE",
	ACTIVITY: "ACTIVITY",
};

export const AppCustomerModal = (props, ref) => {
	const navigate = useNavigate();
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS.PROFILE);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"customer-modal__tab": true,
			"customer-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const onHandleShow = useCallback((user) => {
		setData(user);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	//prettier-ignore
	const onHandleEditProfile = useCallback(() => {
		navigate(pathnames.userManagement.customer + data.id);
	}, [navigate, data]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			let occupation = "";

			try {
				response = await api.get.customer.customer(data.id);
				occupation = await getOccupationItem(response.occupation);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				setData((prev) => ({
					...prev,
					mobile: response.mobileNoPrefix + response.mobileNo || "",
					occupation: occupation.lookupTitle,
				}));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-customer-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="customer-modal" aria-describedby="customer-profile-modal">
			<div className="customer-modal">
				<div className="customer-modal__main">
					<button className="customer-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="customer-modal__header">
						<div className="customer-modal__image">
							<img src={profile} alt="profile" />
						</div>
						<div className="customer-modal__profile">
							<p className="customer-modal__title">{data.fullName}</p>
							<p className="customer-modal__text">
								Last updated by: {data.lastModifiedByName}, {formatDateTime(new Date(data.lastModifiedDate))}
							</p>
						</div>

						<AppButton type="button" label="Edit" icon={editIcon} onClick={onHandleEditProfile} />
					</div>

					<div className="info">
						<div className="info__box">
							<p className="customer-modal__text">Referral Code</p>
							<p className="customer-modal__title">{data.referralCode}</p>
						</div>
						<div className="info__box">
							<p className="customer-modal__text">Total Points</p>
							<p className="customer-modal__title">0</p>
						</div>
						<div className="info__box">
							<p className="customer-modal__text">Status</p>
							<div className="customer-modal__title">
								<AppStatus status={data.status} />
							</div>
						</div>
					</div>

					<div className="customer-modal__tabs">
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.PROFILE)} onClick={() => onHandleTabChange(TABS.PROFILE)}>Profile</button>
						{/*prettier-ignore*/}
						<button className={tabClassName(TABS.ACTIVITY)} onClick={() => onHandleTabChange(TABS.ACTIVITY)}>Activity</button>
					</div>

					{TABS.PROFILE === tab && (
						<div className="customer-modal__body">
							<div className="customer-modal__container">
								<p className="customer-modal__title">Contact Info</p>

								<div className="customer-modal__wrapper">
									<div className="customer-modal__info">
										<p className="customer-modal__label">Date of Birth</p>
										<p className="customer-modal__value">{formatDate(getISOString(data?.dateOfBirth))}</p>
									</div>

									<div className="customer-modal__info">
										<p className="customer-modal__label">Mobile No.</p>
										<p className="customer-modal__value">{data.mobile}</p>
									</div>

									<div className="customer-modal__info">
										<p className="customer-modal__label">Personal Email Address</p>
										<p className="customer-modal__value">{data.email}</p>
									</div>

									<div className="customer-modal__info">
										<p className="customer-modal__label">Occupation</p>
										<p className="customer-modal__value">{data.occupation}</p>
									</div>
								</div>
							</div>
						</div>
					)}

					{TABS.ACTIVITY === tab && (
						<div className="customer-modal__activity-body">
							{/* <div className="customer-modal__container">
								<div className="customer-modal__activities">
									<div className="customer-modal__title">Today</div>

									<div className="customer-modal__activity">
										<div className="customer-modal__item">
											<div className="customer-modal__note">Updated Profile</div>
										</div>
										<div className="customer-modal__text">5 min ago</div>
									</div>
									<div className="customer-modal__activity">
										<div className="customer-modal__item">
											<div className="customer-modal__note">Purchased MSME Insurance</div>
										</div>
										<div className="customer-modal__text">3 hours ago</div>
									</div>
								</div>

								<div className="customer-modal__activities">
									<div className="customer-modal__title">Yesterday</div>

									<div className="customer-modal__activity">
										<div className="customer-modal__item">
											<div className="customer-modal__note">Updated Employment Details</div>
										</div>
										<div className="customer-modal__text">13:15pm</div>
									</div>
									<div className="customer-modal__activity">
										<div className="customer-modal__item">
											<div className="customer-modal__note">Submitted claims</div>
											<div className="customer-modal__text">CLM123456789</div>
										</div>
										<div className="customer-modal__text">11:15am</div>
									</div>
								</div>

								<div className="customer-modal__activities">
									<div className="customer-modal__title">12 Feb 2022</div>

									<div className="customer-modal__activity">
										<div className="customer-modal__item">
											<div className="customer-modal__note">Add new Employment</div>
										</div>
										<div className="customer-modal__text">09:41am</div>
									</div>
								</div>
							</div> */}
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerModal));

AppCustomerModal.propTypes = {
	ref: PropTypes.object,
};
