import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import logo from "assets/images/logo.webp";

const PageAccountSuspended = () => {
	const navigate = useNavigate();

	const onHandleNavigate = useCallback(() => {
		navigate(pathnames.login);
	}, [navigate]);

	return (
		<div className="page-account-suspended">
			<div className="account-suspended">
				<main className="main">
					<div className="main__content main__content--background">
						<img src={logo} alt="logo" className="main__logo" />
					</div>

					<div className="main__content main__content--content">
						<div className="main__wrapper">
							<h1 className="main__title">Your account has been deactivated.</h1>
							<p className="main__description">If you believe this was done in error, please contact our support team for assistance.</p>

							<div className="main__button-container">
								<AppButton type="button" label="Done" onClick={onHandleNavigate} />
							</div>
						</div>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageAccountSuspended;
