import React, { memo } from "react";

const AppReportingIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="reporting-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.03337 5.70662C5.22003 5.70662 5.38003 5.63996 5.5067 5.51329C5.64003 5.37996 5.70003 5.22662 5.70003 5.03996C5.70003 4.85329 5.63337 4.69329 5.5067 4.56662C5.37337 4.43329 5.22003 4.37329 5.03337 4.37329C4.8467 4.37329 4.6867 4.43996 4.56003 4.56662C4.4267 4.69996 4.3667 4.85329 4.3667 5.03996C4.3667 5.22662 4.43337 5.38662 4.56003 5.51329C4.69337 5.64662 4.8467 5.70662 5.03337 5.70662Z"
				fill={color}
			/>
			<path
				d="M5.03337 8.67342C5.22003 8.67342 5.38003 8.60675 5.5067 8.48009C5.64003 8.34675 5.70003 8.19342 5.70003 8.00675C5.70003 7.82009 5.63337 7.66009 5.5067 7.53342C5.37337 7.40009 5.22003 7.34009 5.03337 7.34009C4.8467 7.34009 4.6867 7.40675 4.56003 7.53342C4.4267 7.66675 4.3667 7.82009 4.3667 8.00675C4.3667 8.19342 4.43337 8.35342 4.56003 8.48009C4.69337 8.61342 4.8467 8.67342 5.03337 8.67342Z"
				fill={color}
			/>
			<path
				d="M5.03337 11.6334C5.22003 11.6334 5.38003 11.5667 5.5067 11.44C5.64003 11.3067 5.70003 11.1534 5.70003 10.9667C5.70003 10.78 5.63337 10.62 5.5067 10.4934C5.37337 10.36 5.22003 10.3 5.03337 10.3C4.8467 10.3 4.6867 10.3667 4.56003 10.4934C4.4267 10.6267 4.3667 10.78 4.3667 10.9667C4.3667 11.1534 4.43337 11.3134 4.56003 11.44C4.69337 11.5734 4.8467 11.6334 5.03337 11.6334Z"
				fill={color}
			/>
			<path
				d="M10.4866 2.33333L3.10659 2.33333C2.67992 2.33333 2.33325 2.67999 2.33325 3.10666L2.33325 12.8867C2.33325 13.3133 2.67992 13.66 3.10658 13.66L12.8866 13.66C13.3133 13.66 13.6599 13.3133 13.6599 12.8867L13.6599 5.53999L10.4799 2.32666L10.4866 2.33333Z"
				stroke={color}
				strokeWidth="1.11"
			/>
			<path d="M13.4733 5.72001L10.8667 5.72001C10.4733 5.72001 10.1533 5.40001 10.1533 5.00668L10.1533 2.34668" fill={color} />
		</svg>
	);
};

export default memo(AppReportingIcon);
