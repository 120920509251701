import axios from "axios";

const downloadFiles = async (files = []) => {
	const response = await Promise.all(
		files.map(async (o) => {
			const payload = await axios.get(o.url, { responseType: "arraybuffer" });
			return { data: payload.data, name: o.fileName };
		})
	);
	if (response)
		response.forEach((o) => {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([o.data]));
			a.href = url;
			a.download = o.name;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 0);
		});
};

export default downloadFiles;
