import moment from "moment";

export const CALENDAR_FORMAT = {
	CALENDAR_FORMAT: "YYYY-MM-DD",
	DATE_FORMAT: "DD",
	MONTH_FORMAT: "MMMM",
	MONTH_SHORT_FORMAT: "MMM",
	MONTH_NUMBER_FORMAT: "MM",
	YEAR_FORMAT: "YYYY",
	WEEKDAYS_FORMAT: "ddd",
	WEEKDAYS_LONG_FORMAT: "dddd",
};

export const formatDatePattern = (date, dateFormat) => {
	if (!date) return "-";

	const format = dateFormat || CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

	return moment(new Date(date)).format(format);
};

export const formatDate = (date, format = "/") => {
	if (!date) return "";

	const d = new Date(date);

	return d.getDate().toString().padStart(2, "0") + format + (parseInt(d.getMonth()) + 1).toString().padStart(2, "0") + format + d.getFullYear();
};

export const formatDateTime = (date) => {
	if (!date) return "";

	const today = new Date();
	const year = today.getFullYear();
	let config = { month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };

	if (date.getFullYear() > year) {
		config = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
	}

	const finalDate = date.toLocaleString("en-MY", { ...config });

	if (finalDate === "Invalid Date") return "";

	return finalDate;
};

export const onHandleDisabledDate = (date, maxDate, minDate) => {
	const now = new Date(date);
	let max = new Date(maxDate || new Date("2099-12-31"));
	let min = new Date(minDate);
	min.setDate(min.getDate() - 1);
	max.setDate(max.getDate());

	if (min && isDateBeforeToday(now, min)) return true;
	else if (max && isMaxDateBeforeToday(max, now)) return true;
	else return false;
};

export const getMonths = (format = "MMM") => {
	const today = new Date();
	const thisMonth = today.getMonth() + 1;

	const monthsData = {
		MMMM: [
			{ month: "January", value: 1 },
			{ month: "February", value: 2 },
			{ month: "March", value: 3 },
			{ month: "April", value: 4 },
			{ month: "May", value: 5 },
			{ month: "June", value: 6 },
			{ month: "July", value: 7 },
			{ month: "August", value: 8 },
			{ month: "September", value: 9 },
			{ month: "October", value: 10 },
			{ month: "November", value: 11 },
			{ month: "December", value: 12 },
		],
		MMM: [
			{ month: "Jan", value: 1 },
			{ month: "Feb", value: 2 },
			{ month: "Mar", value: 3 },
			{ month: "Apr", value: 4 },
			{ month: "May", value: 5 },
			{ month: "Jun", value: 6 },
			{ month: "Jul", value: 7 },
			{ month: "Aug", value: 8 },
			{ month: "Sep", value: 9 },
			{ month: "Oct", value: 10 },
			{ month: "Nov", value: 11 },
			{ month: "Dec", value: 12 },
		],
	};

	const months = monthsData[format].map((o) => ({ ...o, thisMonth: thisMonth === o.value }));
	return months;
};

export const getYears = (maxDate, minDate) => {
	const length = 200;
	const arrayOfyears = [...Array(length).keys()];
	const fullYear = new Date("1900").getFullYear();
	const thisYear = new Date().getFullYear().toString();
	const maxYear = new Date(maxDate).getFullYear();
	const minYear = new Date(minDate).getFullYear();

	let years = arrayOfyears.map((_, i) => {
		const value = fullYear + i;
		const y = value.toString();
		const year = { year: y, value, thisYear: thisYear === y };
		return year;
	});

	years = years.filter((o) => {
		if (minDate && maxDate) return o.value >= minYear && o.value <= maxYear;
		else if (minDate) return o.value >= minYear;
		else if (maxDate) return o.value <= maxYear;
		else return o;
	});

	return years;
};
//prettier-ignore
export const getWeekdays = (format = "ddd") => {
	const weeksData = {
		dddd: [
			{ day: "Monday", value: 1 },
			{ day: "Tuesday", value: 2 },
			{ day: "Wednesday", value: 3 },
			{ day: "Thursday", value: 4 },
			{ day: "Friday", value: 5 },
			{ day: "Saturday", value: 6 },
			{ day: "Sunday", value: 7 },
		],
		ddd: [
			{ day: "Mo", value: 1 },
			{ day: "Tu", value: 2 },
			{ day: "We", value: 3 },
			{ day: "Th", value: 4 },
			{ day: "Fr", value: 5 },
			{ day: "Sa", value: 6 },
			{ day: "Su", value: 7 },
		],
		dd: [
			{ day: "M", value: 1 },
			{ day: "T", value: 2 },
			{ day: "W", value: 3 },
			{ day: "T", value: 4 },
			{ day: "F", value: 5 },
			{ day: "S", value: 6 },
			{ day: "S", value: 7 },
		],
	};
	const weekdays = weeksData[format].map((o) => ({ ...o }));
	return weekdays;
};

export const getTotalDays = (now, later) => {
	// To calculate the time difference of two dates
	const differenceInTime = new Date(later).getTime() - new Date(now).getTime();

	// To calculate the no. of days between two dates
	return Math.round(differenceInTime / (1000 * 3600 * 24));
};

export const isDateBeforeToday = (date, today) => {
	return new Date(date).setHours(0, 0, 0, 0) <= new Date(today).setHours(0, 0, 0, 0);
};

export const isMaxDateBeforeToday = (date, today) => {
	return new Date(date).setHours(0, 0, 0, 0) < new Date(today).setHours(0, 0, 0, 0);
};

export const isSameDate = (date, today) => {
	if (!date || !today) return false;
	return new Date(date).toDateString() === new Date(today).toDateString();
};

export const getStartDate = (date) => {
	const d = new Date(new Date(date).setHours(0, 0, 0, 0));
	return d.setDate(1);
};

export const getEndDate = (date) => {
	const endDate = new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(0, 0, 0, 0));
	return endDate;
};

export const getCalendarFirstDate = (startDate) => {
	const d = new Date(startDate);
	let startDay = d.getDay();

	//if startDay = 0(Sunday), need to add previous month 7 days
	if (startDay === 0) startDay = 7;

	d.setDate(d.getDate() - startDay + 1);
	return d;
};

export const addDays = (date, days) => {
	const d = new Date(date);
	d.setDate(d.getDate() + days);
	return d;
};

export const findToday = (date) => {
	const today = new Date();
	return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export const findIsPreviousMonth = (date, startDate) => {
	return date < startDate;
};

export const findIsNextMonth = (date, endDate) => {
	const end = new Date(endDate);
	end.setDate(end.getDate() + 1);
	return date >= end;
};

export const getISOString = (d) => {
	if (!d) return "";
	const today = new Date();
	const date = new Date(d);

	return new Date(date.getTime() - today.getTimezoneOffset() * 60000).toISOString();
};

export const getBetweenDates = (start, end) => {
	const dateFormat = CALENDAR_FORMAT.MONTH_FORMAT + ", " + CALENDAR_FORMAT.WEEKDAYS_LONG_FORMAT;
	const dateArray = [];
	const startDate = start;
	const lastDate = end;
	let currentDate = startDate;

	while (currentDate <= lastDate) {
		const obj = { date: new Date(currentDate).getDate(), day: formatDatePattern(currentDate, dateFormat), d: getISOString(currentDate), today: findToday(new Date(currentDate)), disabled: false, type: "Labour Day" };
		dateArray.push(obj);
		currentDate = addDays(currentDate, 1);
	}

	return dateArray;
};

export const getDates = (dateOfPick, maxDate, minDate) => {
	let days = [];
	let daysCounter = 0;
	let totalCelendarCell = 42;
	const d = dateOfPick ? new Date(dateOfPick || undefined) : new Date();
	const startDate = getStartDate(d);
	const endDate = getEndDate(d);
	const calendarFirstDate = getCalendarFirstDate(startDate);

	while (daysCounter < totalCelendarCell) {
		const nextDate = addDays(calendarFirstDate, daysCounter);
		const day = nextDate.getDay() || 7;
		const date = nextDate.getDate();
		const isToday = findToday(nextDate);
		const isPreviousMonth = findIsPreviousMonth(nextDate, startDate);
		const isNextMonth = findIsNextMonth(nextDate, endDate);
		const isDisabled = onHandleDisabledDate(nextDate, maxDate, minDate);
		const iso = getISOString(nextDate);

		const newDate = { date, day, iso, isToday, isPreviousMonth, isNextMonth, isDisabled };

		days.push(newDate);
		daysCounter++;
	}

	return days;
};
