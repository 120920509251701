import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";

export const AppRetrievePaymentStatusModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({});

	const onHandleShow = useCallback((obj) => {
		setData(obj);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-retrieve-payment-status-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="retrieve-payment-status" aria-describedby="retrieve-payment-status">
			<div className="retrieve-payment-status">
				<div className="retrieve-payment-status__main">
					<div className="retrieve-payment-status__header">
						<p className="retrieve-payment-status__title">Retry Request</p>
					</div>

					<div className="retrieve-payment-status__body">
						<ul className="retrieve-payment-status__list">
							<li className="retrieve-payment-status__item">
								<p className="retrieve-payment-status__label">Payment Reference No</p>
								<p className="retrieve-payment-status__value">{data.orderNo}</p>
							</li>
							<li className="retrieve-payment-status__item">
								<p className="retrieve-payment-status__label">Transation Id</p>
								<p className="retrieve-payment-status__value">{data.transactionId || "-"}</p>
							</li>
							<li className="retrieve-payment-status__item">
								<p className="retrieve-payment-status__label">Status</p>
								<p className="retrieve-payment-status__value">
									<AppStatus status={data.statusCode?.toUpperCase()} />
								</p>
							</li>
							<li className="retrieve-payment-status__item">
								<p className="retrieve-payment-status__label">Message</p>
								<p className="retrieve-payment-status__value">{data.message}</p>
							</li>
							<li className="retrieve-payment-status__item">
								<p className="retrieve-payment-status__label">Code</p>
								<p className="retrieve-payment-status__value">{data.code}</p>
							</li>
						</ul>

						<div className="retrieve-payment-status__button-container">
							<AppButton type="button" label="Close" onClick={onHandleDismiss} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRetrievePaymentStatusModal));

AppRetrievePaymentStatusModal.propTypes = {
	ref: PropTypes.object,
};
