import React, { memo, useRef, useMemo, useCallback, useState } from "react";
import { FormControl, ListSubheader, Select, TextField, MenuItem, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";

import countries from "common/countries";
import AppIcon from "components/app-icon";
import AppChevronIcon from "components/icons/app-chevron-icon";
import classNames from "common/class-names";
import searchIcon from "assets/images/search-icon.svg";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";

const AppMobileInput = ({ onChange, onChangeCode, onFormat, onBlur, onClear, prefixNoName = "prefixNo", ...props }) => {
	const textFieldRef = useRef();
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const [searchValue, setSearchValue] = useState("");
	const prefixCode = useMemo(() => (props.prefixNo ? props.prefixNo : "+60"), [props.prefixNo]);
	const [code, setCode] = useState(prefixCode);
	const [countriesData, setCountriesData] = useState(countries);

	const className = useMemo(() => {
		return classNames({
			"app-mobile-input": true,
			"app-mobile-input--disabled": props.disabled,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.disabled]);

	//prettier-ignore
	const SelectIcon = useCallback((res) => {
		if(props.disabled) return <InputAdornment position="end"><AppIcon src={lockIcon} /></InputAdornment>

		return <div className="app-mobile-input__icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined"><AppChevronIcon {...res} color="#677686" /></div>
	}, [props.disabled]);

	//prettier-ignore
	const onHandleKeyDown = useCallback((event) => {
        switch (event.key) {
            case "e":
            case "-":
            case "+":
            case ".":
                event.preventDefault();
                break;
            default:
                break;
        }
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
        event.target.value = event.target.value.replace(/[^\d]/g, "");
        onChange(event);
	}, [onChange]);

	//prettier-ignore
	const onHandleChangeCode = useCallback((event) => {
		const value = event.target.value;
        setCode(value);
		if(onChangeCode) onChangeCode(prefixNoName, value);
	}, [onChangeCode, prefixNoName]);

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.target.value = event.currentTarget.value.trim();

		if(!props.prefixNo && onChangeCode) onChangeCode(prefixNoName, code);

		if (!onBlur) onChange(event);
	}, [onChange, onChangeCode, onBlur, props.prefixNo, prefixNoName, code]);

	const onHandleSearch = (event) => {
		const searchValue = event.target.value.toLowerCase();
		const filtered = countries.slice(0).filter((o) => {
			const includedCountryCode = o.countryCallingCodes.findIndex((i) => i.replace(/\s/g, "").includes(searchValue));
			return includedCountryCode > -1;
		});

		setSearchValue(searchValue);
		setCountriesData(filtered);
	};

	const InputProps = useMemo(() => {
		const inputSelectProps = {
			endAdornment: (
				<InputAdornment position="end">
					<AppIcon src={searchIcon} />
				</InputAdornment>
			),
		};

		const inputProps = {
			startAdornment: (
				<InputAdornment position="start">
					<Select
						value={prefixCode && countriesData.length ? prefixCode : ""}
						variant="outlined"
						onChange={onHandleChangeCode}
						disabled={props.disabled}
						IconComponent={(p) => <SelectIcon {...p} />}
						MenuProps={{ classes: { root: "app-mobile-input-modal" } }}
					>
						<ListSubheader>
							<TextField name="keyword" type="text" placeholder="Search..." value={searchValue} InputProps={inputSelectProps} onChange={onHandleSearch} onKeyDown={(event) => event.stopPropagation()} />
						</ListSubheader>
						<MenuItem value="" disabled>
							Please Select
						</MenuItem>

						{countriesData.map((o, i) => {
							const c = o.countryCallingCodes;
							return c.map((b, j) => {
								const v = b.replace(/\s/g, "");
								//prettier-ignore
								return <MenuItem value={v} key={j}>{v}</MenuItem>
							});
						})}
					</Select>
				</InputAdornment>
			),
		};

		return inputProps;
	}, [searchValue, prefixCode, countriesData, onHandleChangeCode, props.disabled]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-mobile-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-mobile-input__required">*</span>}
				</label>

				{/*prettier-ignore*/}
				<TextField inputRef={textFieldRef} type="text" autoComplete="off" defaultValue={props.defaultValue} value={props.value} name={props.name} error={isErrorField} helperText={errorMessage} disabled={props.disabled} placeholder={props.placeholder} onChange={onHandleChange} onKeyDown={onHandleKeyDown} onBlur={onHandleBlur} InputProps={InputProps} />
			</FormControl>
		</div>
	);
};

AppMobileInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	onFormat: PropTypes.func,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string || PropTypes.number,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default memo(AppMobileInput);
