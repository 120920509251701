import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

export const AppRemoveUserModal = (props, ref) => {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState();
	const [visible, setVisible] = useState(false);
	const [user, setUser] = useState({});

	const onHandleShow = useCallback(({ ...res }) => {
		setUser(res);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleDelete = async () => {
		setIsSubmitting(true);

		try {
			const payload = { id: user.id };

			await api.post.admin.delete(payload);
			onHandleDismiss();
			props.onHandleGetList();
			dispatch(promptAlertMessage({ message: `${user.fullName} has been deleted` }));
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-remove-user-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="remove-user-modal" aria-describedby="remove-user-modal">
			<div className="remove-user-modal">
				<div className="remove-user-modal__main">
					<div className="remove-user-modal__header">
						<p className="remove-user-modal__title">Remove User</p>
					</div>

					<div className="remove-user-modal__body">
						<p className="remove-user-modal__description">
							Are you sure want to remove <b>{user.fullName}</b> from the list?
						</p>

						<div className="remove-user-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Confirm" onClick={onHandleDelete} disabled={isSubmitting} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRemoveUserModal));

AppRemoveUserModal.propTypes = {
	ref: PropTypes.object,
};
