import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import ERRORS from "common/errors";
import api from "services/api";
import pathnames from "routes/pathnames";
import { setProfile } from "store/slices/profile";
import stringEncryptRSA from "common/string-encrypt-rsa";
import serveRequestErrors from "common/serve-request-errors";
import AppAlert from "components/app-alert";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import logo from "assets/images/logo.webp";

const PageLogin = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			rememberMe: false,
			email: process.env.REACT_APP_LOGIN_EMAIL,
			password: process.env.REACT_APP_LOGIN_PASSWORD,
		},
		validationSchema: yup.object({
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			password: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSignIn(values);
		},
	});

	const onHandleSignIn = async ({ rememberMe, ...values }) => {
		let response = null;
		try {
			response = await api.post.authenticate.signIn({ ...values, password: stringEncryptRSA(values.password) });
		} catch (error) {
			serveRequestErrors(error, (message) => {
				formik.setFieldError("password", message);
			});
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			const token = response.accessToken;
			const refreshToken = response.refreshToken;

			if (rememberMe) {
				localStorage.setItem(COMMON.AUTH_TOKEN, token);
				localStorage.setItem(COMMON.REFRESH_TOKEN, refreshToken);
			} else {
				sessionStorage.setItem(COMMON.AUTH_TOKEN, token);
				sessionStorage.setItem(COMMON.REFRESH_TOKEN, refreshToken);
			}

			onHandleGetProfile();
		}
	};

	const onHandleGetProfile = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.general.profile();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const isForceChangePassword = response.forceChangePassword === COMMON.STATUS_ID.FORCE_CHANGE_PASSWORD;

			dispatch(setProfile(response));

			if (isForceChangePassword) return navigate(pathnames.resetPassword);

			return navigate(pathnames.dashboard);
		}
	}, [navigate, dispatch]);

	//prettier-ignore
	const onHandleRememberMe = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		formik.setFieldValue(name, !value);
	}, [formik]);

	return (
		<div className="page-login">
			<div className="login">
				<AppAlert />

				<main className="main">
					<div className="main__content main__content--background">
						<Link to={pathnames.login} className="main__logo">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div className="main__content main__content--form">
						<form className="main__form" onSubmit={formik.handleSubmit}>
							<h1 className="main__title">Welcome Back!</h1>
							<p className="main__description">Please enter your login details to access the admin portal and manage your account.</p>

							{/* prettier-ignore */}
							<AppInput type="text" name="email" label="Email Address" placeholder="Email Address" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

							<AppInput type="password" name="password" label="Password" placeholder="Password" value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />

							<div className="main__wrapper">
								<AppCheckbox onClick={onHandleRememberMe} name="rememberMe" label="Remember me" value={formik.values.rememberMe} />

								<Link to={pathnames.forgotPassword} className="main__forgot-password">
									Forgot Password?
								</Link>
							</div>

							<div className="main__button-container">
								<AppButton type="submit" label="Login" disabled={formik.isSubmitting} />
							</div>
						</form>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageLogin;
