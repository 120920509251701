import React, { useRef, useCallback, useMemo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import pathnames from "routes/pathnames";
import { formatDateTime } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import queryParamsEntries from "common/query-params-entries";
import capitalizeCharacter from "common/capitalize-character";
import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppCustomerModal from "components/pages/user-management/app-customer-modal";
import AppTable, { indexing, AppTableCell, AppTableMoreIcon } from "components/app-table";
import viewIcon from "assets/images/view-icon.svg";
import editIcon from "assets/images/edit-icon.svg";
import exportIcon from "assets/images/export-icon.svg";
import activeIcon from "assets/images/activate-icon.svg";
import deactivateIcon from "assets/images/deactivate-icon.svg";

const PageCustomers = (props) => {
	const tableRef = useRef();
	const navigate = useNavigate();
	const searchInputRef = useRef();
	const customerModalRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedUser, setSelectedUser] = useState({});
	const [searchParams, setSearchParams] = useSearchParams();
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		status: searchParams.get("status") || "",
		email: searchParams.get("email") || "",
		name: searchParams.get("name") || "",
		phone: searchParams.get("phone") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const createAccessible = restrictedActions(ROLES.USER, ROLES.EXPORT_USER);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isActiveUser = useMemo(() => selectedUser?.status === COMMON.STATUS_ID.ACTIVE, [selectedUser]);
	const isDeletedUser = useMemo(() => selectedUser?.status === COMMON.STATUS_ID.DELETED, [selectedUser]);
	const initialValues = useMemo(() => ({ status: "", email: "", name: "", phone: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.email && !paramsRef.current.name && !paramsRef.current.phone && !paramsRef.current.status, [data.status]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { status: capitalizeCharacter(params.status?.split("_")?.join(" ")), email: params.email, name: params.name, phone: params.phone };

		return values;
	}, [searchParams]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			if (payload.phone) payload.phone = "+" + paramsRef.current.phone;

			response = await api.get.customer.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleUpdateStatus = useCallback(async () => {
		setAnchorEl(null);

		try {
			const payload = {
				id: selectedUser.id,
				status: isActiveUser ? COMMON.STATUS_ID.INACTIVE : COMMON.STATUS_ID.ACTIVE,
			};
			await api.post.customer.updateStatus(payload);

			onHandleGetList();
		} catch (error) {
			serveRequestErrors(error);
		}
	}, [selectedUser, isActiveUser, onHandleGetList]);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const onHandleTableMenu = (event, { row }) => {
		setSelectedUser(row.original);
		setAnchorEl(event.currentTarget);
	};

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	const onhandleViewProfile = () => {
		setAnchorEl(null);
		customerModalRef.current.onHandleShow(selectedUser);
	};

	const onHandleEdit = () => {
		setAnchorEl(null);
		navigate(pathnames.userManagement.customer + selectedUser.id);
	};

	const onHandleExport = () => {};

	const MenuCell = useCallback((props) => {
		return <AppTableMoreIcon onClick={(event) => onHandleTableMenu(event, props)} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Customer Name",
			accessor: "fullName",
			disableSortBy: false,
		},
		{
			Header: "Email Address",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Mobile No.",
			accessor: "fullMobileNo",
			disableSortBy: true,
		},
		{
			Header: "Referral Code",
			accessor: "referralCode",
			disableSortBy: false,
			Cell: ({ row }) => row.original.referralCode || "-",
		},
		{
			Header: <div className="table__total-points">Total Points</div>,
			HeaderLabel: "Total Points",
			id: "points",
			disableSortBy: true,
			accessor: "points",
			Cell: (props) => <AppTableCell right value={0} />,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: ({ row }) => <AppStatus status={row.original.status} />,
		},
		{
			Header: "Last Update by",
			accessor: "lastModifiedDate",
			disableSortBy: false,
			Cell: ({ row }) => <AppTableCell left value={`${row.original.lastModifiedByName || "-"}\n${formatDateTime(new Date(row.original.lastModifiedDate))}`} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CUSTOMER.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-customers">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="customers">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} hideButton={!createAccessible} buttonLabel="Export" buttonIcon={exportIcon} onButtonClick={onHandleExport}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="name" label="Customer Name" placeholder="Enter Customer Name" value={formik.values.name} onChange={formik.handleChange} />
								
								<AppInput type="text" name="email" label="Email Address" placeholder="Enter Email Address" value={formik.values.email} onChange={formik.handleChange} />
								
								<AppInput type="number" name="phone" label="Mobile No." placeholder="Enter Mobile No." value={formik.values.phone} onChange={formik.handleChange} />
								
								<AppSelectInput name="status" label="Status" placeholder="Select Status" options={COMMON.STATUS_DROPDOWNS.CUSTOMER} value={formik.values.status} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{(paramsRef.current.phone || paramsRef.current.email || paramsRef.current.status || paramsRef.current.name) && (
						<div className="customers__results">
							<p className="customers__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />

					{/* prettier-ignore */}
					<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
						<MenuItem onClick={onhandleViewProfile}><img className="app-table-menu__icon" src={viewIcon} alt="view" />View</MenuItem>
						<MenuItem onClick={onHandleEdit}><img className="app-table-menu__icon" src={editIcon} alt="edit" />Edit</MenuItem>
						{!isDeletedUser && <MenuItem onClick={onHandleUpdateStatus}><img className="app-table-menu__icon" src={isActiveUser ? deactivateIcon : activeIcon} alt="status" />{isActiveUser ? "Inactive" : "Active"}</MenuItem>}
					</Menu>
				</div>
			)}

			<AppCustomerModal ref={customerModalRef} />
		</div>
	);
};

export default PageCustomers;
